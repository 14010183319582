import React, { memo } from 'react'

import { Table, useTableStubs } from '@clain/core/ui-kit'
import classnames from 'classnames/bind'
import styles from './AddressTable.scss'
import { useAddressTableConfig } from './hooks/useAddressTableConfig'
import { AddressEvmTableItem, AddressEvmTableProps } from './AddressEvmTable'
import * as S from '../../ui'
import { EntityTableNotFound } from '../../ui'
import { withErrorBoundary } from '@clain/core/ErrorBoundary'

const cx = classnames.bind(styles)

export type AddressProps = Pick<
  AddressEvmTableProps<AddressEvmTableItem>,
  'data' | 'itemsPerPage' | 'isLoading'
> & {
  config: ReturnType<typeof useAddressTableConfig>
  filtersContent?: React.ReactElement
}

const AddressTableComponent = memo(
  ({
    data,
    isLoading,
    itemsPerPage = 10,
    config,
    filtersContent,
  }: AddressProps) => {
    const { stubbedColumns, stubbedData } = useTableStubs(
      Object.values(config),
      data?.map((item, index) => ({
        ...item,
        key: index,
      })),
      itemsPerPage
    )

    const loading = Boolean(data && isLoading)

    return (
      <div className={cx('AddressTableWrapper')}>
        <S.Container>
          {filtersContent ? (
            <S.FilterContainer>{filtersContent}</S.FilterContainer>
          ) : null}
          <S.TableContainer>
            <Table
              columns={stubbedColumns}
              className={cx('AddressTable')}
              data={stubbedData}
              rowHeight={32}
              loading={loading}
              notFoundComponent={<EntityTableNotFound />}
            />
          </S.TableContainer>
        </S.Container>
      </div>
    )
  }
)

export const AddressTable = withErrorBoundary(AddressTableComponent)

AddressTableComponent.displayName = 'AddressTable'
