import {
  BlockTransactionEVM,
  BlockTransactionUTXO,
} from '@platform/apiServices'
import { plotTransactionsOnGraph } from '@platform/modules/plotTransactionsOnGraph'
import { useEffect } from 'react'

export const usePlotTransactionsOnGraph = <
  T extends BlockTransactionEVM | BlockTransactionUTXO
>(
  transactions?: T[] | T
) => {
  useEffect(() => {
    if (Array.isArray(transactions)) {
      plotTransactionsOnGraph.init({ transactions })
    } else if (transactions) {
      plotTransactionsOnGraph.init({ transactions: [transactions] })
    }
  }, [transactions])

  useEffect(() => {
    return () => {
      plotTransactionsOnGraph.clear()
    }
  }, [])

  return plotTransactionsOnGraph
}
