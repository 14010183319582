import { createTokenKey, selectToken, StyledProps } from '@clain/core/ui-kit'
import { styled } from 'styled-components'
import { Stack, Row } from '@clainio/web-platform'
import { Foggy } from '@clain/core/ui-kit'

export const ClusterEntityIcon = styled.div<StyledProps<{ url: string }>>`
  flex: 0 0 12px;
  width: 12px;
  height: 12px;
  background: ${({ $url }) => `center / contain no-repeat url(${$url})`};
  border-radius: ${({ theme }) =>
    `${selectToken(theme, createTokenKey(['border', 'radius', 'sm']))}`};
`
export const AddressLinkStack = styled(Stack)`
  overflow: hidden;
`

export const CounterpartyStack = styled(Stack)`
  flex: 0 1 100%;
  overflow: hidden;
`

export const CounterpartyRow = styled(Row)`
  overflow: hidden;
  width: 100%;
  height: 100%;
  flex: 0 1 auto;
`

export const FoggyStyled = styled(Foggy)`
  min-width: 60px;
`
