import {
  createTokenKey,
  Foggy,
  selectToken,
  Stack,
  StackProps,
  StyledProps,
} from '@clain/core/ui-kit'
import styled, { css } from 'styled-components'

export const VisibilityContainer = styled.div<
  StyledProps<{ visible: boolean }>
>`
  visibility: ${({ $visible }) => ($visible ? 'visible' : 'hidden ')};
`

export const FoggyStyled = styled(Foggy)`
  width: 100%;
`

export const StackCell = styled(Stack)`
  height: 100%;
`

export const TrxEvmTableCell = styled.div`
  width: 100%;
  height: 100%;
  padding: ${({ theme }) =>
    `0 0 0 ${selectToken(theme, createTokenKey(['padding', 'lg']))}`};
`

export const TableCellContainer = styled(Stack).attrs<
  StackProps & StyledProps<{ muted?: boolean }>
>((props) => ({
  ...props,
  align: 'center',
}))`
  width: 100%;
  height: 31px;

  ${({ $muted }) => {
    return css({
      opacity: $muted ? 0.3 : 1,
    })
  }}
`
