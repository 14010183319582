import { CounterpartySelectedTableProps } from '../CounterpartyTableConfig'
import { useDefaultCounterpartyTableConfig } from './useDefaultCounterpartyTableConfig'
import React from 'react'
import {
  Checkbox,
  getScoreColor,
  Row,
  EyeCheckbox,
  Amount,
} from '@clain/core/ui-kit'

import classnames from 'classnames/bind'
import styles from '../CounterpartyTable.scss'
import { useInteraction } from './useInteraction'
import { useCommonTable } from './useCommonTable'

import { DEFAULT_CURRENCY_TOKEN_ID } from '../../../../ProbeSandbox/utils/convertTokenBalances'
import { EntityTableNumber } from '../../../ui'
import { formatMoney, GENERAL_NUMBER_NOTATION } from '@clain/core/utils/format'

const cx = classnames.bind(styles)

export const useProbeCounterpartyTableConfig = (
  props: Omit<CounterpartySelectedTableProps, 'itemsPerPage'>
) => {
  const {
    showInUSD,
    filters,
    coinType,
    setFilters,
    getSearchResults,
    setInflowSelected,
    cumulative,
    setSelected,
    setOutflowSelected,
    setNetflowSelected,
    counterpartyType,
  } = props

  const isAddressCounterparty = counterpartyType === 'address'

  const defaultConfig = useDefaultCounterpartyTableConfig(props)

  const { isAllChecked, isAllLoading, isIndeterminate, handleAllSelected } =
    useInteraction(props)

  const { formatMoney: formatMoneySettings } = useCommonTable({
    coinType,
    filters,
    setFilters,
    getSearchResults,
  })

  const getAmountConfig = (
    amount: number | string,
    amountUsd: number | string
  ) => {
    return showInUSD || filters?.includeTokens?.[0]?.id == null
      ? {
          value: amountUsd,
          currency: 'usd',
          code: 'USD',
        }
      : DEFAULT_CURRENCY_TOKEN_ID.includes(filters?.includeTokens?.[0]?.id)
      ? {
          value: amount,
          currency: coinType,
        }
      : {
          value: amount,
          decimals: filters?.includeTokens[0].decimals,
          code: filters?.includeTokens[0].symbol,
        }
  }

  const selectedConfig = {
    ...defaultConfig,
    counterparty: {
      ...defaultConfig.counterparty,
      width: isAddressCounterparty ? 2.5 : 1.5,
      minWidth: '300px',
      renderTitle: () => (
        <Row align="between" fullWidth>
          <Row>
            <Checkbox
              isLoading={isAllLoading}
              value={isAllChecked}
              onChange={handleAllSelected}
              indeterminate={props?.data?.length ? isIndeterminate : false}
              disabled={!props?.data?.length}
            />
            {defaultConfig.counterparty.renderTitle()}
          </Row>
          <div className={cx('Delimiter')} />
        </Row>
      ),
      render: (item, index, options) => {
        return (
          <Row fullWidth style={{ height: '100%' }}>
            <Checkbox
              isLoading={item.isProcessing}
              value={item.selected}
              onChange={() => {
                setSelected(item, !item.selected)
              }}
              disabled={['Fee', 'Mining'].includes(item.category)}
            />
            {defaultConfig.counterparty.render(item, index, options)}
          </Row>
        )
      },
    },
    totalIn: {
      ...defaultConfig.totalIn,
      render: (item) => {
        const formatTotalInConfig = getAmountConfig(
          item.totalIn,
          item.totalInUsd
        )

        const inflow = showInUSD ? item.totalInUsd : item.totalIn
        const totalInflow = showInUSD
          ? cumulative.totalInUsd
          : cumulative.totalIn

        const inflowLeft = inflow
          ? `${(1 - Number(inflow) / Number(totalInflow)) * 100}%`
          : 'unset'

        const score = item.type === 'cluster' ? item.score : item.address.score

        const inflowMeterStyle = {
          backgroundColor: getScoreColor(score, true),
          left: inflowLeft,
        }

        return (
          <>
            <div className={cx('InflowMeter')} style={inflowMeterStyle} />
            <div className={cx('TotalIn')}>
              <EntityTableNumber>
                {item.totalInUsd ? (
                  <Row align="right" className={cx('EyeRow')}>
                    <Amount
                      variant="body200NormalCode"
                      color="onBackgroundBase"
                      value={formatMoneySettings({
                        ...formatTotalInConfig,
                        formats: GENERAL_NUMBER_NOTATION,
                      })}
                      fullValue={formatMoney({
                        ...formatTotalInConfig,
                        precision: 64,
                        minimumSignificantDigits: 1,
                      })}
                      copyValue={formatMoney({
                        ...formatTotalInConfig,
                        precision: 64,
                        code: '',
                        minimumSignificantDigits: 1,
                      })}
                      enabledCopy
                    />
                    {!['Fee', 'Mining'].includes(item.category) && (
                      <EyeCheckbox
                        className={cx('EyeCheckbox')}
                        value={item.inflowSelected}
                        onChange={() =>
                          setInflowSelected(item, !item.inflowSelected)
                        }
                      />
                    )}
                  </Row>
                ) : (
                  <span className={cx('EmptyEyeRow')}>-</span>
                )}
              </EntityTableNumber>
            </div>
          </>
        )
      },
    },
    totalOut: {
      ...defaultConfig.totalOut,
      width: 1,
      render: (item) => {
        const formatTotalOutConfig = getAmountConfig(
          item.totalOut,
          item.totalOutUsd
        )

        const outflow = showInUSD ? item.totalOutUsd : item.totalOut
        const totalOtflow = showInUSD
          ? cumulative.totalOutUsd
          : cumulative.totalOut

        const outflowRight = outflow
          ? `${(1 - Number(outflow) / Number(totalOtflow)) * 100}%`
          : 'unset'

        const score = item.type === 'cluster' ? item.score : item.address.score

        const outflowMeterStyle = {
          backgroundColor: getScoreColor(score, true),
          right: outflowRight,
        }

        return (
          <>
            <div className={cx('OutflowMeter')} style={outflowMeterStyle} />
            <div className={cx('TotalOut')}>
              <EntityTableNumber>
                {item.totalOutUsd ? (
                  <Row align="right" className={cx('EyeRow')}>
                    <Amount
                      variant="body200NormalCode"
                      color="onBackgroundBase"
                      value={formatMoneySettings({
                        ...formatTotalOutConfig,
                        formats: GENERAL_NUMBER_NOTATION,
                      })}
                      fullValue={formatMoney({
                        ...formatTotalOutConfig,
                        precision: 64,
                        minimumSignificantDigits: 1,
                      })}
                      copyValue={formatMoney({
                        ...formatTotalOutConfig,
                        precision: 64,
                        code: '',
                        minimumSignificantDigits: 1,
                      })}
                      enabledCopy
                    />
                    {!['Fee', 'Mining'].includes(item.category) && (
                      <EyeCheckbox
                        className={cx('EyeCheckbox')}
                        value={item.outflowSelected}
                        onChange={() =>
                          setOutflowSelected(item, !item.outflowSelected)
                        }
                      />
                    )}
                  </Row>
                ) : (
                  <span className={cx('EmptyEyeRow')}>-</span>
                )}
              </EntityTableNumber>
            </div>
          </>
        )
      },
    },
    net: {
      ...defaultConfig.net,
      render: (item) => {
        const formatNetConfig = getAmountConfig(item.net, item.netUsd)

        return (
          <EntityTableNumber>
            <Row align="right" className={cx('EyeRow')}>
              <Amount
                variant="body200NormalCode"
                color="onBackgroundBase"
                value={formatMoneySettings({
                  ...formatNetConfig,
                  formats: GENERAL_NUMBER_NOTATION,
                })}
                fullValue={formatMoney({
                  ...formatNetConfig,
                  precision: 64,
                  minimumSignificantDigits: 1,
                })}
                copyValue={formatMoney({
                  ...formatNetConfig,
                  precision: 64,
                  code: '',
                  minimumSignificantDigits: 1,
                })}
                enabledCopy
              />
              {!['Fee', 'Mining'].includes(item.category) &&
              item.totalInUsd &&
              item.totalOutUsd ? (
                <EyeCheckbox
                  className={cx('EyeCheckbox')}
                  value={item.netflowSelected}
                  onChange={() =>
                    setNetflowSelected(item, !item.netflowSelected)
                  }
                />
              ) : null}
            </Row>
          </EntityTableNumber>
        )
      },
    },
  }

  return selectedConfig as typeof defaultConfig
}
