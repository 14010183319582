import React, { useCallback } from 'react'
import { useNavigate } from '@clain/core/Router/router'
import * as echarts from 'echarts'
import classnames from 'classnames/bind'
import fromUnixTime from 'date-fns/fromUnixTime'

import { fontFamily } from '@clain/core/styles/fonts'
import Chart from '@clain/core/Chart2'
import { KeyValue } from '@clain/core/ui-kit'
import { enterprise, Score } from '@clain/core/ui-kit'
import { Text } from '@clain/core/ui-kit'
import { formatMoney, formatNumber } from '@clain/core/utils/format'
import { FormatDatePreset } from '@clain/core/utils/date'
import { useFormatDate } from '../../hooks'
import { randomInt, stubColor } from '@clain/core/Chart2/mock.utils'
import createTooltipFormatter from '@clain/core/Chart2/createTooltipFormatter'

import styles from './dashboard.scss'
import { useFormatNumberSettings } from '../../hooks/useFormatNumberSettings'
import { useFormatMoneySettings } from '../../hooks/useFormatMoneySettings'
import { ThemeProvider } from 'styled-components'

const cx = classnames.bind(styles)

const generateMockData = (): DashboardOptions => {
  const data = new Array(randomInt(24, 32)).fill(null).map(() => {
    return {
      name: '',
      value: randomInt(60000, 450000),
      color: stubColor,
    }
  })

  return {
    data,
    topBy: 'balance',
    coin: 'btc',
    currency: 'src',
  }
}

interface LeafDataItem {
  name: string
  value: number
  color?: string
  link?: string
}

interface TopLevelDataItem {
  name: string
  value: number
  color: string
  children?: LeafDataItem[]
}

interface DashboardOptions {
  data: TopLevelDataItem[]
  topBy?: string
  coin?: string
  currency?: string
  formatDate?: (date: Date, preset?: FormatDatePreset) => string
  formatNumber?: typeof formatNumber
  formatMoney?: typeof formatMoney
}

const DashboardTooltip = ({ currency, cluster, coin, formatDate }) => {
  const formatNumber = useFormatNumberSettings({ type: 'probe-tabels' })
  const formatMoney = useFormatMoneySettings({ type: 'probe-tabels' })

  if (!cluster) {
    return null
  }

  return (
    <ThemeProvider theme={enterprise['light']}>
      <div className={cx('DashboardTooltip')}>
        <Text bold>{cluster.identity.name}</Text>
        <Score value={cluster.score} className={cx('score')} />

        <KeyValue
          dense
          layout="vertical"
          data={cluster}
          className={cx('details')}
        >
          {[
            {
              key: 'Category',
              value: ({ identity }) => identity.category,
            },
            {
              key: 'Balance',
              value: ({ balance, balance_usd }) =>
                formatMoney({
                  // TODO: как же убого
                  value: currency === 'usd' ? balance_usd : balance,
                  currency,
                }),
            },
            {
              key: 'Addesses',
              value: ({ size }) => formatNumber(size, 0),
            },
            (coin === 'eth' || coin === 'trx') &&
              cluster.transaction_count && {
                key: 'Transactions',
                value: ({ transaction_count }) =>
                  formatNumber(transaction_count, 0),
              },
            {
              key: 'Last seen',
              value: ({ last_seen }) =>
                formatDate(fromUnixTime(last_seen), 'date'),
            },
          ].filter(Boolean)}
        </KeyValue>
      </div>
    </ThemeProvider>
  )
}

const createStaticPostion = () => {
  let prevTooltipX
  let prevTooltipY

  return (point, params, dom, rect, size) => {
    // point — текщая точка где курсор
    // params — даные по текущему квадрату
    // dom — нода тултипа
    // rect — размер блока на который навели (result of getBoundingClientRect)
    // size.viewSize — размер всего графика
    // size.contentSize — размер тултипа

    const padding = 8

    // левая верхняя точка квадрата + его ширина + небольшой отступ =
    // = показываем тултип справа от квадрата
    let x = rect.x + rect.width + padding
    // центруем тултип по середине высоты квадрата
    let y = rect.y + rect.height / 2 - size.contentSize[1] / 2

    // если размер остатка всего графика от места куда будет встроен тултип
    // меньше его ширины, то показываем его слева
    if (size.viewSize[0] - x < size.contentSize[0]) {
      x = rect.x - size.contentSize[0] - padding
    }

    // если тултип вылезает за границу всего графика, то прибиваем его к низу
    if (size.viewSize[1] - y < size.contentSize[1]) {
      y = rect.y + rect.height - size.contentSize[1]
    }

    // очень тупой фикс того, что внутри квадрата можно навести на текст
    // и он будет чуть меньше самого квадрата, из-за этого тултип "прыгал"
    if (Math.abs(x - prevTooltipX) < 15) {
      return [prevTooltipX, prevTooltipY]
    }

    prevTooltipX = x
    prevTooltipY = y

    return [x, y]
  }
}

function getOptions({
  data,
  topBy,
  coin,
  currency,
  formatDate,
  formatNumber,
  formatMoney,
}: DashboardOptions) {
  const gapWidth = 4

  const itemStyle = {
    borderColor: '#fff',
    borderRadius: 2,
    gapWidth,
    shadowColor: 'rgba(140, 152, 164, 0.25)',
    shadowBlur: 0,
  }

  const treemap: echarts.TreemapSeriesOption = {
    name: '',
    type: 'treemap',
    // насколько мелкие элементы показывать
    // TODO: кажется это надо рассчитывать в зависимости от данных
    visibleMin: 1, //10000,
    label: {
      show: true,
      position: 'insideTopLeft',
      formatter: function (params) {
        // Если нет кластера, значит мы сейчас в категории,
        // выводим ее как есть
        // @ts-expect-error
        if (!params.data.cluster) {
          return params.name
        }

        let formattedValue = ''

        if (topBy === 'balance') {
          formattedValue = formatMoney({
            value:
              currency == 'usd'
                ? // @ts-expect-error
                  params.data.cluster.balance_usd
                : // @ts-expect-error
                  params.data.cluster.balance,
            currency,
          })
        }

        if (topBy === 'addresses') {
          formattedValue = `${formatNumber(
            // @ts-expect-error
            params.data.cluster.size,
            0
          )} addresses`
        }

        if (topBy === 'transactions') {
          formattedValue = `${formatNumber(
            // @ts-expect-error
            params.data.cluster.transaction_count,
            0
          )} transactions`
        }

        const arr = [
          '{name|' + params.name + '}',
          '{hr|}',
          '{topBy|' + formattedValue + '}',
        ]

        return arr.join('\n')
      },
      rich: {
        topBy: {
          fontSize: 14,
        },
        name: {
          fontSize: 14,
          fontWeight: 'bold',
          padding: [2, 0, 2, 0],
        },
        hr: {
          width: '100%',
          borderColor: 'rgba(255,255,255,0.4)',
          borderWidth: 0.5,
          height: 0,
          lineHeight: 10,
        },
      },
    },
    // Делаем одинаковые отступы https://echarts.apache.org/en/option.html#series-treemap.left
    left: 12,
    top: 12,
    right: 12,
    bottom: 12,
    // Убираем зум и перетаскивание курсором
    roam: false,

    itemStyle: {
      gapWidth: 24,
    },
    breadcrumb: {
      show: false,
      emptyItemWidth: 0,
    },
    data: data.map(({ color, children, ...item }) => ({
      ...item,
      itemStyle: {
        color,

        gapWidth: 4,
      },
      // TODO: подумать как лучше отображать категорию
      upperLabel: {
        show: true,
        height: 32,
      },
      visibleMin: 0,
      children: children
        ? children.map(({ color, ...item }) => ({
            ...item,
            visibleMin: 0,
            label: {
              // color: getScoreTextColor(item.score),
            },
            itemStyle: {
              ...itemStyle,
              gapWidth: 4,
              color,
            },
          }))
        : null,
    })),
  }

  const options: echarts.EChartsOption = {
    textStyle: {
      fontFamily,
      fontSize: 14,
      // lineHeight: 20,
    },

    tooltip: data
      ? {
          appendToBody: false,
          transitionDuration: 0, // .4,
          // отключаем взаимодействие c тултипом
          enterable: false,
          formatter: createTooltipFormatter(
            (info) => ({ cluster: info.data.cluster }),
            DashboardTooltip,
            { coin, currency, formatDate }
          ),
          // same as in Dropdown2
          extraCssText: 'box-shadow: 0px 4px 40px rgba(0, 17, 158, 0.25);',
          borderWidth: 0,
          // position: createStaticPostion(),
          // вписать тултип в график, чтобы он не обрезался
          confine: true,
        }
      : null,

    series: [treemap],
  }

  return options
}

interface DashboardChartProps extends DashboardOptions {
  className?: string
  loading?: boolean
}

const DashboardChart = React.memo(
  ({
    className,
    data,
    topBy,
    coin,
    currency,
    loading,
  }: DashboardChartProps) => {
    const navigate = useNavigate()
    const formatDate = useFormatDate()
    const formatNumber = useFormatNumberSettings({ type: 'probe-tabels' })
    const formatMoney = useFormatMoneySettings({ type: 'probe-tabels' })

    /* Генерируем мок 1 раз, чтобы в случае непредведенных ререндеров, данные не изменялись */
    const mock = React.useMemo(() => {
      return generateMockData()
    }, [])

    const weakState = React.useRef<any>()
    const [ticker, setTicker] = React.useState(false)

    React.useEffect(() => {
      if (data) {
        weakState.current = getOptions({ data: [] })
        setTicker(true)
      }
    }, [data, currency, coin])

    React.useEffect(() => {
      if (ticker && data) {
        weakState.current = getOptions({
          data,
          topBy,
          coin,
          currency,
          formatDate,
          formatNumber,
          formatMoney,
        })
        setTicker(false)
      }
    }, [ticker, data])

    const option = weakState.current || getOptions(mock)

    const onClick = useCallback(
      (event) => {
        const link = event?.data?.link
        if (link) {
          if (event?.event?.event?.ctrlKey || event?.event?.event?.metaKey) {
            window.open(link, '_blank')
          } else {
            navigate(link)
          }
        }
      },
      [navigate]
    )

    const onEvents = React.useMemo(() => {
      return {
        click: onClick,
      }
    }, [onClick])

    return (
      <Chart
        className={className}
        loading={loading}
        stub={!data}
        option={option}
        style={{
          height: '100%',
          width: '100%',
        }}
        onEvents={onEvents}
      />
    )
  }
)

DashboardChart.displayName = 'DashboardChart'

export default DashboardChart
