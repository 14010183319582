import React from 'react'

import {
  getDefaultUtxoTableConfig,
  TransactionsSelectedUtxoTableProps,
} from './UtxoTable'
import {
  getDefaultEvmTableConfig,
  TransactionsSelectedEvmTableProps,
} from './EvmTable'
import { useDefaultTransactionsTableConfig } from './useDefaultTransactionsTableConfig'
import {
  Checkbox,
  CopyIcon,
  EllipsisTypography,
  StackDeprecated,
  Tooltip,
  Row,
  EllipsisTypographyBlock,
  Stack,
} from '@clain/core/ui-kit'
import { BaseLink } from '@clain/core/Link'
import classnames from 'classnames/bind'
import styles from './TransactionTable.scss'
import { isEVM } from '@clain/core/types/coin'
import { FoggyStyled, TableCellContainer } from './TransactionTable.styles'
import { TranscationInfo } from './components'
import { existSomeScamSpam } from './helpers'

const cx = classnames.bind(styles)
export const useProbeTransactionsTableConfig = (
  props: Omit<
    TransactionsSelectedUtxoTableProps | TransactionsSelectedEvmTableProps,
    'itemsPerPage'
  >
) => {
  const defaultConfig = useDefaultTransactionsTableConfig(props)

  const [isAllTransactionsChecked, setIsAllTransactionsChecked] =
    React.useState(false)
  const [isAllTransactionsLoading, setIsAllTransactionsLoading] =
    React.useState(false)

  React.useEffect(() => {
    // @ts-ignore
    if (props.data?.every((item) => item.selected)) {
      setIsAllTransactionsChecked(true)
    }

    // @ts-ignore
    if (props.data?.every((item) => !item.selected)) {
      setIsAllTransactionsChecked(false)
    }
    // @ts-ignore
    if (props.data?.every((item) => item.isProcessing)) {
      setIsAllTransactionsLoading(true)
    }

    // @ts-ignore
    if (props.data?.every((item) => !item.isProcessing)) {
      setIsAllTransactionsLoading(false)
    }
  }, [props.data])

  const isNotDeterminate = //@ts-ignore
    props.data?.every((item) => item.selected) ||
    //@ts-ignore
    props.data?.every((item) => !item.selected)

  const isIndeterminate = !isNotDeterminate

  const handleChangeTransactionCheckbox = () => {
    const selected = isIndeterminate || !isAllTransactionsChecked
    props.setAllSelected(
      // @ts-ignore
      props.data.filter((item) => (selected ? !item.selected : item.selected)),
      selected
    )
  }

  const selectedConfig = {
    ...defaultConfig,
    transaction: {
      ...defaultConfig.transaction,
      width: 1,
      className: cx('TrxEvmTableCellFirst'),
      renderTitle: () => (
        <Row gap={0.75}>
          <Checkbox
            isLoading={isAllTransactionsLoading}
            value={isAllTransactionsChecked}
            onChange={handleChangeTransactionCheckbox}
            indeterminate={isIndeterminate}
            disabled={!props.data?.length}
          />
          {defaultConfig.transaction.renderTitle()}
        </Row>
      ),
      render: (item) => (
        <TableCellContainer
          $muted={
            isEVM(props.coinType) ? existSomeScamSpam(item.transfers) : false
          }
        >
          <StackDeprecated className={cx('ContentTnx')}>
            <Row className={cx('ContentTnxContent')}>
              <Checkbox
                value={item.selected}
                isLoading={item.isProcessing}
                onChange={() => {
                  return props.setSelected(item, !item.selected)
                }}
              />
              <Row className={cx('ContentTnxContentLink')} gap={0.25}>
                <TranscationInfo
                  failed={isEVM(props.coinType) ? !item?.status : false}
                  hasDemix={item?.hasDemix}
                  hasCrossChainSwap={item?.hasCrossChainSwap}
                />
                <EllipsisTypographyBlock as={Stack} fullWidth>
                  <FoggyStyled
                    color="base"
                    content={<CopyIcon value={item.hash} />}
                  >
                    <Tooltip content={item.hash}>
                      <div style={{ width: '100%', display: 'flex' }}>
                        <BaseLink
                          className={cx('TransactionLink')}
                          to="/:coin/explorer/transaction/:hash"
                          params={{ hash: item.hash, coin: props.coinType }}
                          target="_blank"
                          as={(props) => (
                            <EllipsisTypography
                              {...props}
                              as="a"
                              variant="body200NormalCode"
                              color="onBackgroundBase"
                              type="center"
                            >
                              {item.hash}
                            </EllipsisTypography>
                          )}
                        />
                      </div>
                    </Tooltip>
                  </FoggyStyled>
                </EllipsisTypographyBlock>
              </Row>
            </Row>
          </StackDeprecated>
        </TableCellContainer>
      ),
    },
    ...(isEVM(props.coinType)
      ? {
          from: {
            ...(defaultConfig as ReturnType<typeof getDefaultEvmTableConfig>)
              .from,
            minWidth: '220px',
          },
        }
      : {
          counterparty: {
            ...(defaultConfig as ReturnType<typeof getDefaultUtxoTableConfig>)
              .counterparty,
            minWidth: '220px',
          },
        }),
  }
  return selectedConfig as typeof defaultConfig
}
