import { action, makeObservable } from 'mobx'
import { QueryParamsController } from '@clain/core/utils/queryParamsUtils'
import { StateViewModel } from '@clain/core/utils/mobxUtils'
import { transactionState } from './ApiServicesStateFacade'
import { DEFAULT_TRANSACTIONS_SEARCH_FILTERS } from '../TransactionsSearch.constants'
import { QueryParamsViewModel } from './QueryParamsViewModel'
import { TransactionSearchRequestParams } from '../../../apiServices'
import { convertFromUnixTimestampToLocal } from '@clain/core/utils/date'

export const queryParamsController = new QueryParamsController(
  new StateViewModel<TransactionSearchRequestParams>()
)

class QueryParamsTrxSearchViewModel extends QueryParamsViewModel<
  Partial<TransactionSearchRequestParams>
> {
  private commonPageData = transactionState
  public queryParamsController = queryParamsController

  constructor() {
    super(queryParamsController)
    makeObservable(this)
  }

  public get getUrlParams(): TransactionSearchRequestParams {
    return this.queryParamsController.getUrlFormattedQueryParams({
      page: 'parseAsInt',
      trx_time_to: 'parseAsDate',
      trx_time_from: 'parseAsDate',
      trx_total_amount_from: 'parseAsFloat',
      trx_total_amount_to: 'parseAsFloat',
      input_amount_from: 'parseAsFloat',
      input_amount_to: 'parseAsFloat',
      input_amount_eq: 'parseAsFloat',
      output_amount_from: 'parseAsFloat',
      output_amount_to: 'parseAsFloat',
      output_amount_eq: 'parseAsFloat',
      trx_fee_from: 'parseAsFloat',
      trx_fee_to: 'parseAsFloat',
      trx_fee_eq: 'parseAsFloat',
      trx_fee_per_byte_from: 'parseAsFloat',
      trx_fee_per_byte_to: 'parseAsFloat',
      trx_fee_per_byte_eq: 'parseAsFloat',
      trx_vsize_from: 'parseAsFloat',
      trx_vsize_to: 'parseAsFloat',
      trx_vsize_eq: 'parseAsFloat',
      trx_size_from: 'parseAsFloat',
      trx_size_to: 'parseAsFloat',
      trx_size_eq: 'parseAsFloat',
      trx_num_inputs_from: 'parseAsInt',
      trx_num_inputs_to: 'parseAsInt',
      trx_num_inputs_eq: 'parseAsInt',
      trx_num_outputs_from: 'parseAsInt',
      trx_num_outputs_to: 'parseAsInt',
      trx_num_outputs_eq: 'parseAsInt',
      input_score_from: 'parseAsFloat',
      input_score_to: 'parseAsFloat',
      output_score_from: 'parseAsFloat',
      output_score_to: 'parseAsFloat',
      trx_version: 'parseAsInt',
      trx_rbf: 'parseAsBoolean',
      trx_segwit: 'parseAsBoolean',
      trx_locktime: 'parseAsBoolean',
      input_compressed: 'parseAsBoolean',
      output_compressed: 'parseAsBoolean',
      output_not_a_change: 'parseAsBoolean',
      input_type: 'parseAsInt',
      output_type: 'parseAsInt',
      trx_inputs_all: 'parseAsInt',
      trx_outputs_all: 'parseAsInt',
      input_not_a_multisig: 'parseAsBoolean',
      output_not_a_multisig: 'parseAsBoolean',
      input_multisig: ['parseAsInt', 'parseAsInt'],
      output_multisig: ['parseAsInt', 'parseAsInt'],
    }) as unknown as TransactionSearchRequestParams
  }

  @action
  public initQueryParams = (): void => {
    const prevFilters: Partial<TransactionSearchRequestParams> =
      this.getUrlParams

    const mergedFilters = {
      ...DEFAULT_TRANSACTIONS_SEARCH_FILTERS,
      ...prevFilters,
    }

    this.queryParamsController.initDefaultSchemaQueryParamsState({
      ...DEFAULT_TRANSACTIONS_SEARCH_FILTERS,
      trx_time_from: convertFromUnixTimestampToLocal(
        DEFAULT_TRANSACTIONS_SEARCH_FILTERS.trx_time_from
      ),
      trx_time_to: convertFromUnixTimestampToLocal(
        DEFAULT_TRANSACTIONS_SEARCH_FILTERS.trx_time_to
      ),
    })

    this.queryParamsController.initQueryParamsState(mergedFilters)
  }
}

export const queryParamsTrxSearchViewModel = new QueryParamsTrxSearchViewModel()
