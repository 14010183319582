import {
  DefaultUtxoTableConfig,
  TableColumnConfig,
  TransactionsUtxoTableItem,
} from './TransactionsUtxoTable.types'
import { BaseLink } from '@clain/core/Link'
import { TransactionFeatureTags } from '../../../../ProbeSandbox/ui/FeautureTags'
import {
  CopyIcon,
  EllipsisTypography,
  EllipsisTypographyBlock,
  Score,
  Stack,
  TypographyNew,
} from '@clain/core/ui-kit'
import { TableSortButton, AssetsListShort, Row } from '@clain/core/ui-kit'
import AssetsRow from '../../../../ProbeSandbox/ui/AssetsRow'
import fromUnixTime from 'date-fns/fromUnixTime'
import React from 'react'
import classnames from 'classnames/bind'
import styles from './TransactionsUtxoTable.scss'
import * as S from './TransactionsUtxoTable.styles'
import { Amount, PopoverBaseContainer, Tooltip } from '@clainio/web-platform'
import { TranscationInfo } from '../components'
import { formatMoney, GENERAL_NUMBER_NOTATION } from '@clain/core/utils/format'
import {
  DEFAULT_CURRENCY_TOKEN_ID,
  isDefaultTokenId,
} from '../../../../ProbeSandbox/utils/convertTokenBalances'
import { FoggyStyled } from '../TransactionTable.styles'

const cx = classnames.bind(styles)

export const getDefaultUtxoTableConfig = ({
  filters,
  showInUSD,
  isLoading,
  coinType,
  formatDate,
  formatMoney: formatMoneySettings,
  onChangeSort,
}: DefaultUtxoTableConfig<TransactionsUtxoTableItem>) => {
  const getAmountConfig = (
    amount: number | string,
    amountUsd: number | string
  ) => {
    return showInUSD || filters?.includeTokens?.[0]?.id == null
      ? {
          value: amountUsd,
          currency: 'usd',
          code: 'USD',
        }
      : DEFAULT_CURRENCY_TOKEN_ID.includes(filters?.includeTokens?.[0]?.id)
      ? {
          value: amount,
          currency: coinType,
        }
      : {
          value: amount,
          decimals: filters?.includeTokens[0].decimals,
          code: filters?.includeTokens[0].symbol,
        }
  }

  const defaultConfig: Record<
    | 'transaction'
    | 'features'
    | 'direction'
    | 'counterparty'
    | 'amount'
    | 'tokens'
    | 'fee'
    | 'fee_byte'
    | 'time',
    TableColumnConfig<TransactionsUtxoTableItem>
  > = {
    transaction: {
      name: 'transaction',
      width: 1.2,
      renderTitle: () => (
        <Row gap={0.5}>
          <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
            Transaction
          </TypographyNew>
        </Row>
      ),
      render: (item) => (
        <Row className={cx('TransactionRow')}>
          <FoggyStyled color="base" content={<CopyIcon value={item.hash} />}>
            <TranscationInfo
              hasDemix={item?.hasDemix}
              hasCrossChainSwap={item?.hasCrossChainSwap}
            />
            <EllipsisTypographyBlock as={Stack} fullWidth>
              <Tooltip content={item.hash}>
                <BaseLink
                  className={cx('TransactionLink')}
                  to="/:coin/explorer/transaction/:hash"
                  params={{ hash: item.hash, coin: coinType }}
                  target="_blank"
                  as={(props) => (
                    <EllipsisTypography
                      {...props}
                      as="a"
                      variant="body200NormalCode"
                      color="onBackgroundBase"
                      type="center"
                    >
                      {item.hash}
                    </EllipsisTypography>
                  )}
                />
              </Tooltip>
            </EllipsisTypographyBlock>
          </FoggyStyled>
        </Row>
      ),
      renderFooter: null,
    },
    features: {
      name: 'features',
      width: 0.75,
      minWidth: '140px',
      renderTitle: () => (
        <Row gap={0.5}>
          <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
            Features
          </TypographyNew>
        </Row>
      ),
      render: (transaction) => (
        <TransactionFeatureTags transaction={transaction} />
      ),
    },
    direction: {
      name: 'direction',
      width: 0.5,
      align: 'center',
      renderTitle: () => (
        <Row gap={0.5}>
          <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
            Direction
          </TypographyNew>
        </Row>
      ),
      render: ({ direction }) => (
        <TypographyNew
          variant="body200Normal"
          transform="capitalize"
          color="onBackgroundVariant1"
        >
          {direction}
        </TypographyNew>
      ),
    },
    counterparty: {
      name: 'counterparty',
      width: 1,
      renderTitle: () => (
        <Row gap={0.5}>
          <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
            Counterparty
          </TypographyNew>
        </Row>
      ),
      render: ({ counterparties }) => {
        if (!counterparties?.length) return null

        const largestCounterparty = counterparties[0]
        const othersCpCount = counterparties.length - 1
        const largestCounterpartyClusterId = largestCounterparty.clusterId

        return (
          <Row gap={0.5} className={cx('CounterpartiesRow')}>
            <Score value={largestCounterparty.score} size="xs" />
            {!largestCounterpartyClusterId ? (
              'Processing...'
            ) : largestCounterpartyClusterId === 0 ? (
              'Mining'
            ) : (
              <S.ClusterContainer align={'center'} gap={'sm'}>
                {largestCounterparty?.icon && (
                  <S.ClusterEntityIcon $url={largestCounterparty?.icon} />
                )}
                <BaseLink
                  to="/:coin/cluster/:clusterId"
                  params={{
                    clusterId: largestCounterpartyClusterId,
                    coin: coinType,
                  }}
                  target="_blank"
                  as={(props) => (
                    <EllipsisTypography
                      {...props}
                      as="a"
                      variant="body200NormalCode"
                      color="onBackgroundBase"
                      type="end"
                    >
                      {largestCounterparty.name || largestCounterpartyClusterId}
                    </EllipsisTypography>
                  )}
                />
              </S.ClusterContainer>
            )}
            {othersCpCount > 0 && (
              <S.CounterpartiesOthersCount
                variant="body200NormalCode"
                color="onBackgroundVariant1"
              >
                +{othersCpCount} other
              </S.CounterpartiesOthersCount>
            )}
          </Row>
        )
      },
    },
    amount: {
      name: 'amount',
      width: 1,
      align: 'right',
      renderTitle: () => (
        <Row gap={0.5}>
          <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
            Amount
          </TypographyNew>
          <TableSortButton
            name="amount"
            onChangeSort={onChangeSort}
            order={filters.sortOrder}
            sortBy={filters.sortBy}
            disabled={isLoading}
          />
        </Row>
      ),
      render: ({ amount, amountUsd }) => {
        const formatAmountConfig = getAmountConfig(amount, amountUsd)

        return (
          <Amount
            variant="body200NormalCode"
            color="onBackgroundBase"
            value={formatMoneySettings({
              ...formatAmountConfig,
              formats: GENERAL_NUMBER_NOTATION,
            })}
            fullValue={formatMoney({
              ...formatAmountConfig,
              precision: 64,
              minimumSignificantDigits: 1,
            })}
            copyValue={formatMoney({
              ...formatAmountConfig,
              precision: 64,
              code: '',
              minimumSignificantDigits: 1,
            })}
            enabledCopy
          />
        )
      },
      renderFooter: null,
    },
    tokens: {
      name: 'tokens',
      width: 0.5,
      align: 'center',
      renderTitle: () => {
        return (
          <Row gap={0.5}>
            <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
              Assets
            </TypographyNew>
          </Row>
        )
      },
      render: ({ tokens }) => (
        <PopoverBaseContainer
          maxHeight={300}
          content={
            <AssetsListShort
              assets={tokens}
              isDefaultTokenId={isDefaultTokenId}
              size="sm"
              useNewIcons
            />
          }
          anchor={
            <span>
              <AssetsRow
                className={cx('AssetsRow')}
                buttonMode
                tokens={tokens}
              />
            </span>
          }
        />
      ),
    },
    fee: {
      name: 'fee',
      width: 1,
      align: 'right',
      renderTitle: () => (
        <Row gap={0.5}>
          <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
            Fee
          </TypographyNew>
          <TableSortButton
            name="fee"
            onChangeSort={onChangeSort}
            order={filters.sortOrder}
            sortBy={filters.sortBy}
            disabled={isLoading}
          />
        </Row>
      ),
      render: ({ fee }) => {
        if (fee == null) return null

        const fromaFeeConfig = {
          value: fee,
          currency: coinType,
        }

        return (
          <Amount
            variant="body200NormalCode"
            color="onBackgroundBase"
            value={formatMoneySettings({
              ...fromaFeeConfig,
              formats: GENERAL_NUMBER_NOTATION,
            })}
            fullValue={formatMoney({
              ...fromaFeeConfig,
              precision: 64,
              minimumSignificantDigits: 1,
            })}
            copyValue={formatMoney({
              ...fromaFeeConfig,
              precision: 64,
              code: '',
              minimumSignificantDigits: 1,
            })}
            enabledCopy
          />
        )
      },
    },
    fee_byte: {
      name: 'fee_byte',
      width: 1,
      align: 'right',
      renderTitle: () => (
        <Row gap={0.5}>
          <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
            Fee/ byte
          </TypographyNew>
          <TableSortButton
            name="fee_byte"
            onChangeSort={onChangeSort}
            order={filters.sortOrder}
            sortBy={filters.sortBy}
            disabled={isLoading}
          />
        </Row>
      ),
      render: ({ feeByte }) => {
        if (feeByte == null) return null

        const fromaFeeConfig = {
          value: feeByte,
          precision: 2,
          code: 'sat/vB',
        }

        return (
          <Amount
            variant="body200NormalCode"
            color="onBackgroundBase"
            value={formatMoneySettings({
              ...fromaFeeConfig,
              formats: GENERAL_NUMBER_NOTATION,
            })}
            fullValue={formatMoney({
              ...fromaFeeConfig,
              precision: 64,
              minimumSignificantDigits: 1,
            })}
            copyValue={formatMoney({
              ...fromaFeeConfig,
              precision: 64,
              code: '',
              minimumSignificantDigits: 1,
            })}
            enabledCopy
          />
        )
      },
    },
    time: {
      name: 'time',
      width: 1,
      align: 'right',
      renderTitle: () => (
        <Row gap={0.5}>
          <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
            Time
          </TypographyNew>
          <TableSortButton
            name="time"
            onChangeSort={onChangeSort}
            order={filters.sortOrder}
            sortBy={filters.sortBy}
            disabled={isLoading}
          />
        </Row>
      ),
      render: ({ time }) => (
        <TypographyNew color="onBackgroundBase" variant="body200NormalCode">
          {formatDate(fromUnixTime(time), 'date-time-numeric')}
        </TypographyNew>
      ),
    },
  }
  return defaultConfig
}
