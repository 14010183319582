import React, { useEffect, useState } from 'react'

import { useFilterSlice } from '../TrxFilters.service'
import { CalendarInput } from '../FilterLayout/FilterLayout.styles'
import {ErrorBoundaryContainer} from "@clain/core/ErrorBoundary";

export const CalendarFilter = () => {
  const [values, { setFilter }] = useFilterSlice('calendar.data')

  const [date, setDate] = useState(
    values ? values : ([] as unknown as [Date, Date])
  )

  useEffect(() => {
    setDate(values)
  }, [values])

  const onSubmitCalendar = (hide: () => void | undefined) => {
    setFilter(date)
    hide?.()
  }

  return (
    <CalendarInput
      ErrorBoundaryWrapper={ErrorBoundaryContainer}
      showTimeOnLabel={true}
      size={'md'}
      mode={'datetime'}
      variant={'outline'}
      value={date?.length ? date : undefined}
      onChange={(value) => setDate(value as [Date, Date])}
      requiredRange
      onSubmit={onSubmitCalendar}
      submitButtonText="APPLY"
    />
  )
}
