import React from 'react'
import classnames from 'classnames/bind'
import { observer } from 'mobx-react-lite'
import Pagination from '@clain/core/Pagination'
import Portlet from '@clain/core/Portlet'
import styles from './AddressEntitiesTable.scss'
import { addressPageViewModel } from '../../../EntityPage/AddressViewModels'
import { CounterTabs, Row, MagicGrid, CheckboxSwitch } from '@clain/core/ui-kit'
import {
  TransactionTable,
  useDefaultTransactionsTableConfig,
  TransactionFilters,
  useDefaultCounterpartyTableConfig,
  CounterpartyTable,
  CounterpartyFilters,
  OsintTable,
} from '../../../EntitiesTable'
import { useTableHeaderTabs } from './AddressTableEntities.hooks'
import { toJS } from 'mobx'
import { isEVM, isUTXO } from '@clain/core/types/coin'

const cx = classnames.bind(styles)

const AddressEntitiesTableComponent = <T,>(
  _props: T,
  ref: React.Ref<HTMLDivElement>
) => {
  const {
    defaultTransactionsTableConfig,
    defaultCounterpartiesTableConfig,
    defaultOsintTableConfig,
    transactionsTable,
    counterpartiesTable,
    osintTable,
    activeTab,
    page,
    setPage,
    totalPages,
    setActiveTab,
    isSwitchButtonDisabled,
    isSwitchButtonShowUSD,
    onSwitchUSDButton,
  } = addressPageViewModel.sectionEntitiesTableViewModel
  const tableHeaderTabs = useTableHeaderTabs({
    transactionsCounterValue: transactionsTable?.data?.totalEntries,
    counterpartiesCounterValue: counterpartiesTable?.data?.totalEntries,
    osintCounterValue: osintTable?.data?.totalEntries,
  })

  const transactionConfig = useDefaultTransactionsTableConfig(
    defaultTransactionsTableConfig
  )
  const counterpartyConfig = useDefaultCounterpartyTableConfig(
    defaultCounterpartiesTableConfig
  )
  const transactionsFilters = toJS(defaultTransactionsTableConfig.filters)
  const counterpartiesFilters = toJS(defaultCounterpartiesTableConfig.filters)
  const showSwitchUSD =
    (isUTXO(addressPageViewModel.blockchain) ||
      (isEVM(addressPageViewModel.blockchain) &&
        activeTab !== 'transactions')) &&
    activeTab !== 'osint'

  return (
    <MagicGrid gap={0.5}>
      <Portlet variant="card" ref={ref}>
        {{
          body: (
            <div className={cx('TableWrapper')}>
              <div className={cx('TopPanel')}>
                <Row className={cx('TopPanelContent')} align="between">
                  <CounterTabs
                    options={tableHeaderTabs}
                    value={activeTab}
                    onChange={setActiveTab}
                    fullHeight
                  />
                  <Row gap={0}>
                    <Pagination
                      value={page}
                      totalPages={totalPages}
                      onChange={setPage}
                      className={cx('Pagination', {
                        visible: true,
                        withMarginRight: showSwitchUSD,
                      })}
                    />
                    <div>
                      {showSwitchUSD && (
                        <CheckboxSwitch
                          disabled={isSwitchButtonDisabled}
                          label={'USD'}
                          value={isSwitchButtonShowUSD}
                          onChange={onSwitchUSDButton}
                        />
                      )}
                    </div>
                  </Row>
                </Row>
              </div>
              {activeTab === 'counterparties' && (
                <CounterpartyTable
                  filtersContent={
                    <CounterpartyFilters
                      counterpartyType={
                        defaultCounterpartiesTableConfig.counterpartyType
                      }
                      categories={defaultCounterpartiesTableConfig?.categories}
                      filters={counterpartiesFilters}
                      initialFilters={toJS(
                        defaultCounterpartiesTableConfig?.initFilters
                      )}
                      defaultFilters={toJS(
                        defaultCounterpartiesTableConfig?.defaultFilters
                      )}
                      excludeFilters={
                        defaultTransactionsTableConfig.excludeFilters
                      }
                      filterSize="md"
                      coinType={addressPageViewModel.blockchain}
                      getSearchResults={
                        defaultCounterpartiesTableConfig.counterpartyType ===
                        'cluster'
                          ? defaultCounterpartiesTableConfig.getEntitiesResults
                          : defaultCounterpartiesTableConfig.getBlockchainResults
                      }
                      allowedRange={
                        defaultCounterpartiesTableConfig.allowedRange
                      }
                      tokens={defaultCounterpartiesTableConfig.tokens}
                      setFilters={defaultCounterpartiesTableConfig.setFilters}
                    />
                  }
                  data={counterpartiesTable?.data?.counterparties || []}
                  isLoading={counterpartiesTable?.isLoading}
                  itemsPerPage={20}
                  config={counterpartyConfig}
                />
              )}
              {activeTab === 'transactions' && (
                <TransactionTable
                  filtersContent={
                    <TransactionFilters
                      counterpartyType={
                        defaultCounterpartiesTableConfig.counterpartyType
                      }
                      filterType="default"
                      currency={addressPageViewModel.blockchain}
                      filters={transactionsFilters}
                      initialFilters={toJS(
                        defaultTransactionsTableConfig.initFilters
                      )}
                      defaultFilters={toJS(
                        defaultTransactionsTableConfig.defaultFilters
                      )}
                      excludeFilters={
                        defaultTransactionsTableConfig.excludeFilters
                      }
                      tokens={defaultTransactionsTableConfig.tokens}
                      disabledAssetStaticSearch={
                        defaultTransactionsTableConfig.disabledAssetStaticSearch
                      }
                      onSearchAsset={
                        defaultTransactionsTableConfig.onSearchAsset
                      }
                      searchAssetLoading={
                        defaultTransactionsTableConfig.searchAssetLoading
                      }
                      filterSize="md"
                      getSearchResults={
                        defaultCounterpartiesTableConfig.counterpartyType ===
                        'cluster'
                          ? defaultCounterpartiesTableConfig.getEntitiesResults
                          : defaultCounterpartiesTableConfig.getBlockchainResults
                      }
                      allowedRange={defaultTransactionsTableConfig.allowedRange}
                      setFilters={defaultTransactionsTableConfig.setFilters}
                    />
                  }
                  data={transactionsTable?.data?.transactions || []}
                  isLoading={transactionsTable?.isLoading}
                  itemsPerPage={20}
                  config={transactionConfig}
                />
              )}
              {activeTab === 'osint' && (
                <OsintTable
                  data={toJS(defaultOsintTableConfig.data)}
                  filters={toJS(defaultOsintTableConfig.filters)}
                  setFilters={defaultOsintTableConfig.setFilters}
                  isLoading={defaultOsintTableConfig.isLoading}
                  isVisitedLink={defaultOsintTableConfig.isVisitedLink}
                  visitedLinkAdd={defaultOsintTableConfig.visitedLinkAdd}
                  itemsPerPage={20}
                  currency={defaultOsintTableConfig.currency}
                />
              )}
            </div>
          ),
        }}
      </Portlet>
    </MagicGrid>
  )
}
export const AddressEntitiesTable = observer(AddressEntitiesTableComponent, {
  forwardRef: true,
})
