import { action, makeObservable, observable, reaction, toJS } from 'mobx'

import { ProbeViewModel } from '../ProbeViewModel'
import OsintActiveEntity from './OsintActiveEntity'
import type { ActiveEntityType } from './ActiveEntityViewModel.types'
import {
  addressesFetch,
  counterpartiesFetch,
  osintsFetch,
  tokenByAddressFetch,
  tokensFetch,
  transactionsFetch,
} from './ActiveEntityFetch'
import { ActiveEntityCluster } from './ActiveEntityCluster'
import { BaseNodeData } from '../../types/nodeEntitiesData/BaseNodeData'
import { TransactionAddressNodeData } from '../../types/nodeEntitiesData/TransactionAddressNodeData'
import type { CurrentActiveEntity } from '../../ui/ProbeAnalyticsLayer/index.types'
import { activeEntityEvents } from './ActiveEntityEvents'
import { ActiveEntityAddress } from './ActiveEntityAddress'
import { ActiveEntityFlow } from './ActiveEntityFlow'
import { TransactionEvmActiveEntity } from './TransactionEvmActiveEntity'
import { TransactionUtxoActiveEntity } from './TransactionUtxoActiveEntity'
import { TransactionAddressUtxoActiveEntity } from './TransactionAddressUtxoActiveEntity'
import { TransactionUtxo } from '../../types/converted/TransactionUtxo'
import { ClusterNodeData } from '../../types/nodeEntitiesData/ClusterNodeData'
import { AddressNodeDataUtxo } from '../../types/nodeEntitiesData/AddressNodeDataBtc'
import DemixActiveEntity from './DemixActiveEntity'
import { CoinType } from '../../../../types/coin'
import { isEVM, isUTXO } from '@clain/core/types/coin'
import { ProbeEdges } from '../ProbeState'
import { FlowEdgeData } from '../../types/edgeEntitiesData/FlowEdgeData'
import { AddressNodeDataEth } from '../../types/nodeEntitiesData/AddressNodeDataEth'
import { ActiveEntityAnalyticsViewModel } from './ActiveEntityAnalytics'
import { AnalyticsService } from '../../../../apiServices/analytics'
import { convertTokenBalances } from '../../utils/convertTokenBalances'
import { activeEntityCacheModel } from './ActiveEntityCacheModel'
import { assertsEntityCurrency } from '../../utils/assertEntityCurrency'
import type { IProbeEvents } from '../ProbeEvents'
import { ActiveEntityCrossChainSwapFlow } from './ActiveEntityCrossChainSwapFlow'
import { plotParentController } from '../PlotParentController'
import { paletteController } from '../PaletteController'
import { isEmpty } from 'ramda'

class ActiveEntityViewModel {
  @observable public currency: CoinType
  @observable public type: ActiveEntityType
  @observable public selectedKey: string

  private activeEntityCacheModel = activeEntityCacheModel
  public cluster = new ActiveEntityCluster(activeEntityEvents)
  public address = new ActiveEntityAddress(
    activeEntityEvents,
    plotParentController
  )
  public crossChainSwap = new ActiveEntityCrossChainSwapFlow()
  public transactionEvm = new TransactionEvmActiveEntity(
    activeEntityEvents,
    paletteController
  )
  public transactionUtxo = new TransactionUtxoActiveEntity(
    activeEntityEvents,
    paletteController
  )
  public transactionAddress = new TransactionAddressUtxoActiveEntity(
    activeEntityEvents,
    plotParentController,
    paletteController
  )
  public flow = new ActiveEntityFlow(
    activeEntityEvents,
    this.probeEvents,
    paletteController
  )
  public osint = new OsintActiveEntity(this)
  public demix = new DemixActiveEntity(this)
  public analytics: ActiveEntityAnalyticsViewModel

  public probeVM: ProbeViewModel

  constructor(probeVM: ProbeViewModel, private probeEvents: IProbeEvents) {
    this.probeVM = probeVM

    makeObservable(this)
  }

  private initClosingActiveEntity = () => {
    reaction(
      () =>
        !this.probeVM.probeState.selectedNode?.key &&
        !this.probeVM.probeState.selectedEdge?.key,
      (status) => {
        if (status) {
          this.clearAllState()
        }
      }
    )
  }

  public init = () => {
    AnalyticsService.getInstance()
    this.analytics = new ActiveEntityAnalyticsViewModel()
    this.initClosingActiveEntity()
  }

  @action
  public loadAnalytics = ({
    entityId,
    entityType,
  }: {
    entityId: number
    entityType: Extract<ActiveEntityType, 'cluster' | 'address'>
  }) => {
    const activeEntity = this[this.type] as CurrentActiveEntity
    const isInitTransactionsByFlags =
      isUTXO(this.currency) && entityType === 'cluster'

    const period: [Date, Date] = [
      new Date(activeEntity?.data.firstSeen * 1000),
      new Date(activeEntity?.data.lastSeen * 1000),
    ]

    this.analytics.initAnalytics({
      entityId,
      entityType: entityType,
      blockchain: this.currency,
      isInitTransactionsByFlags,
      tokensBalance: activeEntity?.tokensBalance?.length
        ? activeEntity?.tokensBalance
        : convertTokenBalances([], this.currency),
      initialFilters: {
        period,
        calendar: period,
        convertTo: 'native',
      },
    })
  }

  @action
  public clearAnalytics() {
    this.analytics.clear()
  }

  @action
  private finalize = (type: ActiveEntityType, currency: CoinType) => {
    if (!type) return

    this.type = type
    this.currency = currency
    this.selectedKey =
      this.probeVM.probeState.selectedNode?.key ||
      this.probeVM.probeState.selectedEdge?.key

    const isActiveBottombar = Boolean(
      this.type &&
        this.type !== 'osint' &&
        this.type !== 'cross_chain_swap_flow'
    )
    const isActiveInfobar = Boolean(this.type)

    this.probeVM.probeState.setIsBottombarActive(isActiveBottombar)
    this.probeVM.probeState.setIsInfobarActive(isActiveInfobar)
  }

  private selecteCurrencyType = () => {
    const probeNode = this.probeVM.probeState.selectedNode
    const probeEdge = this.probeVM.probeState.selectedEdge

    assertsEntityCurrency(probeNode?.data)
    let currency = probeNode?.data?.currency
    let nodeType = probeNode?.data?.nodeType

    if (probeEdge && !probeNode) {
      if (probeEdge.sourceAttributes.data.nodeType === 'address') {
        nodeType = probeEdge.sourceAttributes.data.nodeType
        currency = probeEdge.sourceAttributes.data.currency
      } else if (probeEdge.targetAttributes.data.nodeType === 'address') {
        nodeType = probeEdge.targetAttributes.data.nodeType
        currency = probeEdge.targetAttributes.data.currency
      } else {
        const { data } = probeEdge.sourceAttributes
        assertsEntityCurrency(data)

        nodeType = data.nodeType
        currency = data.currency
      }
    }

    return { currency, nodeType }
  }

  private selectService() {
    const { nodeType, currency } = this.selecteCurrencyType()
    if (nodeType === 'cluster') {
      return this.probeVM.entityServices.getServices(nodeType, currency)
    }

    if (nodeType === 'address') {
      return this.probeVM.entityServices.getServices(nodeType, currency)
    }
  }

  private initEntity() {
    const probeNode = this.probeVM.probeState.selectedNode
    const probeEdge = this.probeVM.probeState.selectedEdge
    assertsEntityCurrency(probeNode?.data)
    const currency = probeNode?.data?.currency

    if (currency) {
      this.cluster?.init(currency)
      this.address?.init(currency)
      this.transactionAddress?.init(currency, this.probeVM.entityServices)
    }

    if (probeEdge && !probeNode) {
      const { data } = probeEdge.sourceAttributes
      assertsEntityCurrency(data)
      this.flow?.init(data.currency)
      this.crossChainSwap.init(this.probeVM.entityServices)
    }
  }
  /**
   * Updates a transaction address based on the type provided.
   *
   * If the current transaction has references to a previous or next transaction already present on the graph,
   * updates the address with reference transaction. If not, uses the transaction hash from the current transaction.
   *
   * @param {"transactionAddressBtc"|"transactionAddressDoge"|"transactionAddressLtc"} type The type of transaction address to be updated.
   * @param {string} key The key of the node to update.
   * @param {TransactionAddressNodeData & BaseNodeData} data The data object containing transaction details.
   * @private
   */
  private updateTransactionAddress = (
    key: string,
    data: TransactionAddressNodeData & BaseNodeData
  ) => {
    const { address, transactionHash } = data
    const isPreviousOnGraph =
      data?.previous &&
      this.probeVM.probeState.nodes.has(data?.previous?.trxHash)
    const isNextOnGraph =
      data?.next && this.probeVM.probeState.nodes.has(data?.next?.trxHash)
    const isExtendedOnGraph = isPreviousOnGraph || isNextOnGraph
    const isInitOnGraph = this.probeVM.probeState.nodes.has(transactionHash)

    const updateTypeAddress = (trxHash: string) => {
      return this.transactionAddress.initState({
        hash: address,
        transactionAddress: data,
        transaction: this.probeVM.probeState.nodes.get(trxHash)
          .data as TransactionUtxo,
        key,
      })
    }

    if (isExtendedOnGraph && isInitOnGraph) {
      return updateTypeAddress(transactionHash)
    }
    if (isPreviousOnGraph) {
      return updateTypeAddress(data.previous.trxHash)
    }
    if (isNextOnGraph) {
      return updateTypeAddress(data.next.trxHash)
    }
    return updateTypeAddress(transactionHash)
  }

  private updateFlow = (
    key: string,
    data: FlowEdgeData,
    probeEdge: ProbeEdges
  ) => {
    const updateFlowHelper = (
      id: number,
      sourceData: any,
      targetData: any,
      direction: 'both' | 'out' | 'in',
      counterpartyId: number,
      counterpartyType: 'address' | 'cluster'
    ) => {
      this.flow.update(id, {
        sourceData,
        targetData,
        edgeKey: key,
        counterpartyType,
        counterpartyId,
        direction,
        oppositeAmount: data.oppositeAmount,
      })
    }

    const { data: sourceData } = probeEdge.sourceAttributes
    const { data: targetData } = probeEdge.targetAttributes

    if (
      sourceData.nodeType === 'cluster' &&
      targetData.nodeType === 'cluster'
    ) {
      updateFlowHelper(
        sourceData.clusterId,
        sourceData,
        targetData,
        data.net ? 'both' : 'out',
        targetData.id,
        'cluster'
      )
    }

    if (
      sourceData.nodeType === 'cluster' &&
      targetData.nodeType === 'address'
    ) {
      updateFlowHelper(
        targetData.addressId,
        sourceData,
        targetData,
        data.net ? 'both' : 'in',
        sourceData.id,
        'cluster'
      )
    }

    if (
      sourceData.nodeType === 'address' &&
      targetData.nodeType === 'cluster'
    ) {
      updateFlowHelper(
        sourceData.addressId,
        sourceData,
        targetData,
        data.net ? 'both' : 'out',
        targetData.id,
        'cluster'
      )
    }

    if (
      sourceData.nodeType === 'address' &&
      targetData.nodeType === 'address'
    ) {
      updateFlowHelper(
        sourceData.addressId,
        sourceData,
        targetData,
        data.net ? 'both' : 'out',
        targetData.addressId,
        'address'
      )
    }
  }

  private updateCluster = (data: ClusterNodeData) => {
    const { clusterId } = data

    this.cluster.update(clusterId, data)
  }

  private updateAddress = (data: AddressNodeDataUtxo | AddressNodeDataEth) => {
    const { addressId: id } = data

    this.address.update(id, data)
  }

  private updateEntity = () => {
    let type: ActiveEntityType = undefined
    let currency: CoinType = undefined

    const nodesCount = this.probeVM.probeState.selectedNodeIds.size
    const edgesCount = this.probeVM.probeState.selectedEdgeIds.size

    if (edgesCount === 1 && !nodesCount) {
      const [key] = Array.from(this.probeVM.probeState.selectedEdgeIds)
      if (!this.probeVM.probeState.edges.has(key)) return
      const probeEdge = this.probeVM.probeState.edges.get(key)
      const { data } = probeEdge

      if (data.edgeType === 'flow') {
        type = data.edgeType
        this.updateFlow(key, data, probeEdge)
        assertsEntityCurrency(probeEdge.sourceAttributes.data)
        currency = probeEdge.sourceAttributes.data.currency
      }

      if (data.edgeType === 'cross_chain_swap_flow') {
        type = data.edgeType
        this.crossChainSwap.update(data)
        assertsEntityCurrency(probeEdge.sourceAttributes.data)
        currency = probeEdge.sourceAttributes.data.currency
      }
    }

    if (nodesCount === 1) {
      const [key] = Array.from(this.probeVM.probeState.selectedNodeIds)
      if (!this.probeVM.probeState.nodes.has(key)) return
      const probeNode = this.probeVM.probeState.nodes.get(key)
      const { data } = probeNode

      if (data.nodeType === 'cluster') {
        type = data.nodeType
        this.updateCluster(data)
        currency = data.currency
      }

      if (data.nodeType === 'address') {
        type = data.nodeType
        this.updateAddress(data)
        currency = data.currency
      }

      if (data.nodeType === 'utxo_transaction') {
        type = 'transaction'
        this.transactionUtxo.initState(data)
        currency = data.currency
      }
      if (data.nodeType === 'evm_transaction') {
        type = 'transaction'
        this.transactionEvm.update(data)
        currency = data.currency
      }

      if (data.nodeType === 'utxo_transaction_address' && !data.coinbase) {
        if (isUTXO(data.currency)) {
          type = 'transactionAddress'
          this.updateTransactionAddress(key, data)
        }
        currency = data.currency
      }

      if (data.nodeType === 'osint') {
        type = 'osint'
        this[type].update(data)
        currency = data.currency
      }
    }

    return { type, currency }
  }

  @action
  private clearAllState = () => {
    if (this.type) {
      if (this.type === 'transaction') {
        isEVM(this.currency)
          ? this.transactionEvm.clear()
          : this.transactionUtxo.clear()
      } else {
        this[this.type]?.clear()
      }
    }
    this.type = undefined
    this.selectedKey = undefined
    this.currency = undefined
    this.probeVM.probeState.setIsBottombarActive(false)
    this.probeVM.probeState.setIsInfobarActive(false)
    this.probeVM.setIsAnalyticsLayerActive(false)
    this.clearAnalytics()
  }

  @action
  public hideActive = ({ hasKeyChanged }: { hasKeyChanged: boolean }) => {
    const selectedNode = this.probeVM.probeState.selectedNode
    const selectedEdge = this.probeVM.probeState.selectedEdge

    if (!hasKeyChanged) {
      this.clearAllState()

      return
    }

    if (
      this.selectedKey &&
      (selectedNode?.key === this.selectedKey ||
        selectedEdge?.key === this.selectedKey)
    ) {
      return
    }

    this.clearAllState()
  }

  @action
  private injectRequestMethod = () => {
    const selectedService = this.selectService()
    if (selectedService) {
      const { currency } = this.selecteCurrencyType()

      counterpartiesFetch.injectRequestMethod(selectedService.getCounterparties)
      transactionsFetch.injectRequestMethod(selectedService.getTransactions)
      addressesFetch.injectRequestMethod(
        this.probeVM.entityServices.getServices('cluster', currency)
          .getAddresses
      )
      osintsFetch.injectRequestMethod(selectedService.getOsints)
      tokensFetch.injectRequestMethod(selectedService.getTokens)
      tokenByAddressFetch.injectRequestMethod(async (_, payload) => {
        if (!payload?.address) return

        return await this.probeVM.entityServices
          .getServices('explorer', currency)
          .getTokenByAddress(payload)
      })
    }
  }

  @action
  public detectType(): void {
    const selectedNode = this.probeVM.probeState.selectedNode
    const selectedEdge = this.probeVM.probeState.selectedEdge

    if (
      this.selectedKey &&
      (selectedNode?.key === this.selectedKey ||
        selectedEdge?.key === this.selectedKey)
    ) {
      return
    }

    this.clearAllState()
    this.initEntity()
    this.injectRequestMethod()
    const { type, currency } = this.updateEntity()
    this.finalize(type, currency)
  }

  public clear = () => {
    this.activeEntityCacheModel.clear()
  }
}

export default ActiveEntityViewModel
