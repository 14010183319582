import { injectable, inject } from 'inversify'
import { action, computed, makeObservable, observable } from 'mobx'
import { mergeDeepLeft, pick } from 'ramda'

import { ISubscribable, Subscribable } from '@clain/core/utils/Subscribable'

import { AddVirtualNodes } from './modules/AddVirtualNodes'
import { NodesPositions } from './modules/NodesPositions'
import { DeleteEntityController } from './modules/DeleteEntityController'

import {
  applyEntitiesMeta,
  applyEventsMeta,
  groupEventsByEventType,
  nodesInProcessingKey,
} from './GraphEntities.utils'

import { INIT_PROBE_EVENTS_OPTIONS } from './GraphEntities.constants'
import { GRAPH_ENTITIES_TYPES } from './constants/injectTypes'

import type { ServerNodeEvent } from './types/serverData'
import type { IAddedEntities } from './modules/AddedEntities'
import type { FlowId, IEventsMeta } from './EventsMeta'
import type {
  GraphEmitEvents,
  IGraphEvents,
  IGenerateEntityEvents,
  IGraphAddNodeMeta,
  IServerNodeEventsMeta,
  IDeleteEntitiesMeta,
  GraphEventsOptions,
  GraphEventsSubscribeParams,
  IServerUpdatePositionMeta,
  ReturnMeta,
  IServerEdgeEventsMeta,
} from './GraphEvents.types'

@injectable()
export class GraphEvents<
  TReturnPromise extends Record<string, any>,
  MetaOptions extends object = object
> implements IGraphEvents<TReturnPromise>
{
  private events = new Subscribable<IServerNodeEventsMeta>()
  private addNodeEvent = new Subscribable<IGraphAddNodeMeta>()
  private addEdgeEvent = new Subscribable<IServerEdgeEventsMeta>()
  private updateNodeEvent = new Subscribable<IServerNodeEventsMeta>()
  private updateEdgeEvent = new Subscribable<IServerNodeEventsMeta>()
  private updatePositionEvent = new Subscribable<IServerUpdatePositionMeta>()
  private deleteEntities = new Subscribable<IDeleteEntitiesMeta>()
  private reciveEvents = new Subscribable<
    GraphEventsSubscribeParams<TReturnPromise>
  >()
  @observable private eventsMeta: IEventsMeta<GraphEventsOptions>

  @observable private inPeddingFlows: Array<number> = []

  constructor(
    @inject(GRAPH_ENTITIES_TYPES.AddedEntities)
    private addedEntities: IAddedEntities,
    @inject(GRAPH_ENTITIES_TYPES.DeleteEntityController)
    private deleteEntityController: DeleteEntityController,
    @inject(GRAPH_ENTITIES_TYPES.GenerateEntityEvents)
    private generateEntityEvents: IGenerateEntityEvents,
    @inject(GRAPH_ENTITIES_TYPES.NodesPositions)
    private nodesPositions: NodesPositions,
    @inject(GRAPH_ENTITIES_TYPES.AddVirtualNodes)
    private addVirtualNodes: AddVirtualNodes,
    @inject(GRAPH_ENTITIES_TYPES.EventsMeta)
    eventsMeta: IEventsMeta<GraphEventsOptions>,
    @inject(GRAPH_ENTITIES_TYPES.EventsDefaultMetaOptions)
    private defaultMetaOptions?: MetaOptions
  ) {
    makeObservable(this)
    const publishSync = this.publishSyncEvents()
    this.eventsMeta = eventsMeta

    // add nodes flow
    this.addNodeEvent.subscribe(this.generateEntityEvents.produce)
    this.generateEntityEvents.subscribe(this.nodesPositions.layout)
    this.generateEntityEvents.subscribe(this.addedEntities.clear)
    this.nodesPositions.subscribe(publishSync)
    //add edges flow
    this.addEdgeEvent.subscribe(({ events, meta }) => {
      this.addVirtualNodes.add({ events, meta })
      publishSync({ events, meta })
    })

    //update edges flow
    this.updateEdgeEvent.subscribe(publishSync)

    //update node flow
    this.updateNodeEvent.subscribe(publishSync)

    //update position flow
    this.updatePositionEvent.subscribe(publishSync)

    //remove flow
    this.deleteEntities.subscribe(this.deleteEntityController.delete)
    this.deleteEntityController.subscribe(publishSync)
  }

  @action
  private publishSyncEvents = () => {
    let accEvents: ServerNodeEvent[] = []

    return ({ events, meta }: IServerNodeEventsMeta) => {
      this.inPeddingFlows.shift()

      const isReadyFlows = !this.inPeddingFlows.length

      accEvents.push(...events)

      if (isReadyFlows) {
        this.events.publish({ events: accEvents, meta })
        accEvents = []
      }
    }
  }

  @action
  private setFlowLoading = (id: FlowId) => {
    this.eventsMeta.setLoading(id, true)
  }

  @action
  private addNodesInProgress = (id: FlowId, events: GraphEmitEvents[]) => {
    this.eventsMeta.addNodesInProcessing(id, nodesInProcessingKey(events))
  }

  @action
  public emit = (events: GraphEmitEvents[], options?: GraphEventsOptions) => {
    const id = options?.id ?? Math.random()
    const meta = {
      options: mergeDeepLeft(
        { ...options, id },
        {
          ...INIT_PROBE_EVENTS_OPTIONS,
          ...(this.defaultMetaOptions ? this.defaultMetaOptions : {}),
        }
      ),
      id,
    }
    if (!events.length) {
      return { meta }
    }

    this.setFlowLoading(id)
    this.addNodesInProgress(id, events)

    const groupedEvents = groupEventsByEventType(events)
    const groupedKeys = Object.keys(groupedEvents)

    groupedKeys.forEach((key) => {
      if (groupedEvents[key]?.length) {
        this.inPeddingFlows.push(1)
      }
    })

    if (groupedEvents.addNodes?.length) {
      this.addNodeEvent.publish(applyEventsMeta(groupedEvents.addNodes, meta))
    }

    if (groupedEvents.addEdges?.length) {
      this.addEdgeEvent.publish(applyEventsMeta(groupedEvents.addEdges, meta))
    }

    if (groupedEvents.updateEdges?.length) {
      this.updateEdgeEvent.publish(
        applyEventsMeta(groupedEvents.updateEdges, meta)
      )
    }

    if (groupedEvents.updateNodes?.length) {
      this.updateNodeEvent.publish(
        applyEventsMeta(groupedEvents.updateNodes, meta)
      )
    }

    if (groupedEvents.deleteEntities?.length) {
      this.deleteEntities.publish(
        applyEntitiesMeta(groupedEvents.deleteEntities, meta)
      )
    }

    if (groupedEvents.updatePositions?.length) {
      this.updatePositionEvent.publish(
        applyEventsMeta(groupedEvents.updatePositions, meta)
      )
    }

    return { meta }
  }

  @computed
  public get meta(): ReturnMeta {
    return pick(
      [
        'loading',
        'nodesInProcessing',
        'nodesIsInProcessing',
        'nodesInProcessingFlow',
      ],
      this.eventsMeta
    )
  }

  @action
  public initSaveRequest = (
    cb: (params: ServerNodeEvent[]) => Promise<TReturnPromise[]>
  ) => {
    this.events.subscribe(async ({ events, meta }) => {
      try {
        const resultEvents = await cb(events)

        this.addVirtualNodes.reset({ events, meta })

        if (!resultEvents?.length) {
          return
        }

        if (meta?.options?.optimistic) {
          return
        }
        this.reciveEvents.publish({
          events: resultEvents,
          meta,
        })
      } catch (e) {
        this.addVirtualNodes.reset({ events, meta })
        throw new Error(e as any)
      } finally {
        this.eventsMeta.setLoading(meta.id, false)
        this.eventsMeta.deleteNodesInProcessing(meta.id)
      }
    })
  }

  @action
  public subscribe = (
    cb: Parameters<
      ISubscribable<
        GraphEventsSubscribeParams<TReturnPromise, MetaOptions>
      >['subscribe']
    >[0]
  ) => {
    return this.reciveEvents.subscribe(cb)
  }

  @action
  public subscribeEvents = (cb: (msg: IServerNodeEventsMeta) => void) => {
    return this.events.subscribe(cb)
  }

  public clear = () => {
    this.reciveEvents.clearSubscribers()
    this.events.clearSubscribers()
  }
}
