import {
  Row,
  Tooltip,
  IconButton,
  CoinType,
  BlockchainIcon,
  Icon,
} from '@clain/core/ui-kit'
import { CoinTypeEVM, CoinTypeUTXO } from '../../../../types/coin'
import { UTXO_COINS } from '@clain/core/utils/currency'
import { useNavigate, useParams } from '@clain/core/Router/router'
import { createTnxPath } from '../../../../constants'
import capitalizeFirstLetter from '@clain/core/utils/capitalizeFirstLetter'

export const CurrencyFilter = () => {
  const changePath = useNavigate()
  const params = useParams<{ coin: CoinType }>()

  const coins: CoinTypeUTXO[] = UTXO_COINS
  const disabledCoins: Record<CoinTypeEVM, string> = {
    eth: 'eth',
    trx: 'trx',
    bnb: 'bnb',
  }

  return (
    <Row gap={0.25}>
      {coins.map((coin) => (
        <IconButton
          key={coin}
          active={params?.coin === coin}
          iconOriginal
          onClick={() => {
            changePath(createTnxPath(coin))
          }}
          customIcon={() => (
            <BlockchainIcon color="original" size="sm" currency={coin} />
          )}
        ></IconButton>
      ))}

      {Object.keys(disabledCoins).map((coin: CoinTypeEVM) => (
        <Tooltip key={coin} content={`${disabledCoins[coin]} coming soon`}>
          <div>
            <IconButton
              disabled
              customIcon={() => (
                <Icon size="sm" variant={capitalizeFirstLetter(coin)} />
              )}
            />
          </div>
        </Tooltip>
      ))}
    </Row>
  )
}
