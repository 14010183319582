import React from 'react'
import { useTransactionBlock } from '../TransactionBlockContext'
import {
  Col,
  CopyCursorContainer,
  EllipsisTypography,
  formatMoney,
  Row,
  Tag,
  Tooltip,
} from '@clainio/web-platform'
import { HeaderLayout } from '../components'
import { ERROR_TRANSACTION } from '../TransactionBlock.constants'
import { RowList } from './RowList'
import { RowAddressItem } from '../components/RowAddressItem'
import { getEVMTagConfig } from '../../../utils/getEVMTagConfig'
import {
  itemLayoutConfig,
  sortInternalsByIndex,
} from './TransactionBlockEVM.utils'
import { sort } from 'ramda'
import { GENERAL_NUMBER_NOTATION } from '@clain/core/utils/format'
import { useSelectedCurrency } from '../../NavIcons'
import {
  AmountContainerStyled,
  AmountStyled,
  AssetChipCointainer,
  AmountContainer,
  RowAddressItemEVMStyled,
  TagsContainerStyled,
} from './TransactionBlockEVM.styles'
import { isDefaultTokenId } from '@platform/components/ProbeSandbox/utils/convertTokenBalances'
import { AssetChip, Stack } from '@clain/core/ui-kit'

export const TransactionBlockEVM = () => {
  const {
    transaction,
    blockchain,
    isLinkToTransactionDisabled,
    isShowFullTransaction,
    onSelectTransaction,
    selectedTransaction = false,
  } = useTransactionBlock<'EVM'>()
  const currency = useSelectedCurrency()
  const layoutConfig = itemLayoutConfig[blockchain]
  const getAmount = (
    amountUsd: number | string,
    amount: string,
    ticker: string | null,
    decimals?: string | null,
    tokenIcon?: string | null,
    spam?: boolean,
    scam?: boolean
  ) => {
    const tokenConfig =
      decimals != null
        ? {
            decimals: Number(decimals),
          }
        : {}
    const amountConfig = {
      ...tokenConfig,
      value: currency === 'usd' ? amountUsd : Number(amount),
      currency: currency === 'usd' ? currency : blockchain,
    }
    const amountFormatted = formatMoney({
      formats: GENERAL_NUMBER_NOTATION,
      ...amountConfig,
      code: '',
    })

    const tickerValue =
      currency === 'usd'
        ? currency.toUpperCase()
        : ticker != null
        ? ticker.toUpperCase()
        : ''

    const assetChipVariant = !spam && !scam ? 'licit' : scam ? 'scam' : 'spam'

    return (
      <AmountContainerStyled>
        <AmountStyled>
          <CopyCursorContainer
            value={formatMoney({
              ...amountConfig,
              precision: currency === 'usd' ? 2 : 64,
              code: '',
              minimumSignificantDigits: 1,
            })}
          >
            <Tooltip
              content={formatMoney({
                ...amountConfig,
                precision: currency === 'usd' ? 2 : 64,
                minimumSignificantDigits: 1,
                code: tickerValue,
              })}
            >
              <Stack gap="md" align="center" justify="end" fullWidth>
                <AmountContainer>
                  <EllipsisTypography
                    variant={'body200NormalCode'}
                    color={'onBackgroundBase'}
                  >
                    {amountFormatted}
                  </EllipsisTypography>
                </AmountContainer>
                <AssetChipCointainer>
                  <AssetChip
                    interactive={false}
                    variant={assetChipVariant}
                    icon={tokenIcon}
                    id={
                      currency === 'usd' ? 0 : blockchain !== ticker ? 9999 : 2
                    }
                    color={
                      isDefaultTokenId(
                        currency === 'usd'
                          ? 0
                          : blockchain !== ticker
                          ? 9999
                          : 2
                      )
                        ? 'original'
                        : 'grey'
                    }
                    blockchain={blockchain}
                  >
                    {tickerValue}
                  </AssetChip>
                </AssetChipCointainer>
              </Stack>
            </Tooltip>
          </CopyCursorContainer>
        </AmountStyled>
      </AmountContainerStyled>
    )
  }

  const renderItem = (
    data:
      | (typeof transaction.transfers)[number]['sender']
      | (typeof transaction.transfers)[number]['receiver']
      | (typeof transaction.tokens)[number]['sender']
      | (typeof transaction.tokens)[number]['receiver']
      | (typeof transaction.internals)[number]['sender']
      | (typeof transaction.internals)[number]['receiver'],
    key: string
  ) => {
    const newData = data || ({} as typeof data)
    if (!data) {
      newData.clusterId = null
      newData.contract = true
    }

    const { label, ...tagProps } = getEVMTagConfig(
      newData.contract ? 'CA' : 'EOA'
    )

    return (
      <RowAddressItem
        clusterWidthPercent={25}
        addressWidthPercent={75}
        wrapper={RowAddressItemEVMStyled}
        key={key}
        score={newData.score}
        address={newData.address}
        blockchain={blockchain}
        clusterId={newData.clusterId}
        clusterName={newData.entity?.name}
        rightChildren={
          <Row gap={1.25}>
            <TagsContainerStyled>
              {data && (
                <Tag {...tagProps} size={'xs'}>
                  {label}
                </Tag>
              )}
            </TagsContainerStyled>
          </Row>
        }
      />
    )
  }

  return (
    <Col>
      <HeaderLayout
        isLinkToTransactionDisabled={isLinkToTransactionDisabled}
        hash={transaction.hash}
        blockchain={blockchain}
        time={transaction.time}
        selected={selectedTransaction}
        onSelect={
          onSelectTransaction
            ? (checked) => onSelectTransaction(transaction, checked)
            : undefined
        }
      >
        {transaction.status === false && (
          <Tooltip content={ERROR_TRANSACTION.tooltipContent}>
            <Tag variant={'critical'} type={'outline'} size={'xs'}>
              {ERROR_TRANSACTION.label}
            </Tag>
          </Tooltip>
        )}
        {transaction?.hasDemix && (
          <Tag variant="critical" type="solid" size="xs">
            AUTO-DEMIX AVAILABLE ON GRAPH
          </Tag>
        )}
        {transaction?.hasCrossChainSwap && (
          <Tag variant="accent1" type="solid" size="xs">
            CROSS-CHAIN SWAP AVAILABLE ON GRAPH
          </Tag>
        )}
      </HeaderLayout>

      {/*<RowList
        layoutConfig={layoutConfig}
        isShowFullTransaction={isShowFullTransaction}
        list={transaction.transfers}
        renderListFrom={(props) =>
          renderItem(props.sender, `from-${props.sender.addressId}`)
        }
        renderListTo={(props) =>
          renderItem(props.receiver, `to-${props.receiver?.addressId}`)
        }
        renderExtraRight={(props) =>
          getAmount(props.usd, props.value, props.token?.symbol || blockchain)
        }
      />*/}
      <RowList
        layoutConfig={layoutConfig}
        isShowFullTransaction={isShowFullTransaction}
        title={'Internals'}
        list={sort<(typeof transaction.internals)[number]>(
          sortInternalsByIndex,
          transaction.internals
        )}
        renderListFrom={(props, idx) =>
          renderItem(
            props.sender,
            `from-${props.index}-${idx}-${props.sender.addressId}`
          )
        }
        renderListTo={(props, idx) =>
          renderItem(
            props.receiver,
            `to-${props.index}-${idx}-${props.receiver?.addressId}`
          )
        }
        renderExtraRight={(props) =>
          getAmount(props.usd, props.value, blockchain)
        }
      />
      <RowList
        layoutConfig={layoutConfig}
        isShowFullTransaction={isShowFullTransaction}
        title={'Tokens'}
        list={transaction.tokens}
        renderListFrom={(props, idx) =>
          renderItem(
            props.sender,
            `from-${props.token.id}-${idx}-${props.sender.addressId}`
          )
        }
        renderListTo={(props, idx) =>
          renderItem(
            props.receiver,
            `to-${props.token.id}-${idx}-${props.receiver?.addressId}`
          )
        }
        renderExtraRight={(props) =>
          getAmount(
            props.usd,
            props.value,
            props.token.symbol,
            props.token?.decimals?.toPrecision(),
            props.token?.icon,
            props.token?.spam,
            props.token?.scam
          )
        }
      />
    </Col>
  )
}
