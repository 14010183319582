import React from 'react'
import classnames from 'classnames/bind'
import { observer } from 'mobx-react-lite'
import Pagination from '@clain/core/Pagination'
import Portlet from '@clain/core/Portlet'
import styles from './ClusterEntitiesTable.scss'
import { clusterPageViewModel } from '../../../EntityPage/ClusterViewModels'
import { CounterTabs, Row, MagicGrid, CheckboxSwitch } from '@clain/core/ui-kit'
import {
  TransactionTable,
  useDefaultTransactionsTableConfig,
  TransactionFilters,
  useDefaultCounterpartyTableConfig,
  CounterpartyTable,
  CounterpartyFilters,
  useAddressTableConfig,
  AddressTable,
  AddressFilters,
  OsintTable,
} from '../../../EntitiesTable'
import { useTableHeaderTabs } from './ClusterEntitiesTable.hooks'
import { toJS } from 'mobx'
import { isEVM, isUTXO } from '@clain/core/types/coin'

const cx = classnames.bind(styles)

const ClusterEntitiesTableComponent = <T,>(
  _props: T,
  ref: React.Ref<HTMLDivElement>
) => {
  const {
    defaultTransactionsTableConfig,
    defaultCounterpartiesTableConfig,
    defaultAddressesTableConfig,
    defaultOsintTableConfig,
    transactionsTable,
    counterpartiesTable,
    addressesTable,
    osintTable,
    activeTab,
    page,
    setPage,
    totalPages,
    setActiveTab,
    isSwitchButtonDisabled,
    isSwitchButtonShowUSD,
    onSwitchUSDButton,
  } = clusterPageViewModel.sectionEntitiesTableViewModel

  const tableHeaderTabs = useTableHeaderTabs({
    transactionsCounterValue: transactionsTable?.data?.totalEntries,
    counterpartiesCounterValue: counterpartiesTable?.data?.totalEntries,
    addressesCounterValue: addressesTable?.data?.totalEntries,
    osintCounterValue: osintTable?.data?.totalEntries,
  })

  const transactionConfig = useDefaultTransactionsTableConfig(
    defaultTransactionsTableConfig
  )
  const counterpartyConfig = useDefaultCounterpartyTableConfig(
    defaultCounterpartiesTableConfig
  )
  const addressConfig = useAddressTableConfig(defaultAddressesTableConfig)
  const transactionsFilters = toJS(defaultTransactionsTableConfig.filters)
  const counterpartiesFilters = toJS(defaultCounterpartiesTableConfig.filters)
  const addressesFilters = toJS(defaultAddressesTableConfig.filters)
  const showSwitchUSD =
    (isUTXO(clusterPageViewModel.blockchain) ||
      (isEVM(clusterPageViewModel.blockchain) &&
        activeTab !== 'transactions')) &&
    activeTab !== 'osint'

  return (
    <MagicGrid gap={0.5}>
      <Portlet variant="card" ref={ref}>
        {{
          body: (
            <div className={cx('TableWrapper')}>
              <div className={cx('TopPanel')}>
                <Row className={cx('TopPanelContent')} align="between">
                  <CounterTabs
                    options={tableHeaderTabs}
                    value={activeTab}
                    onChange={setActiveTab}
                    fullHeight
                  />
                  <Row gap={0}>
                    <Pagination
                      value={page}
                      totalPages={totalPages}
                      onChange={setPage}
                      className={cx('Pagination', {
                        visible: true,
                        withMarginRight: showSwitchUSD,
                      })}
                    />
                    <div>
                      {showSwitchUSD && (
                        <CheckboxSwitch
                          disabled={isSwitchButtonDisabled}
                          label={'USD'}
                          value={isSwitchButtonShowUSD}
                          onChange={onSwitchUSDButton}
                        />
                      )}
                    </div>
                  </Row>
                </Row>
              </div>
              {activeTab === 'counterparties' && (
                <CounterpartyTable
                  filtersContent={
                    <CounterpartyFilters
                      counterpartyType={'cluster'}
                      categories={defaultCounterpartiesTableConfig?.categories}
                      filters={counterpartiesFilters}
                      initialFilters={toJS(
                        defaultCounterpartiesTableConfig?.initFilters
                      )}
                      defaultFilters={toJS(
                        defaultCounterpartiesTableConfig.defaultFilters
                      )}
                      filterSize="md"
                      coinType={clusterPageViewModel.blockchain}
                      getSearchResults={
                        defaultCounterpartiesTableConfig.getEntitiesResults
                      }
                      excludeFilters={
                        defaultCounterpartiesTableConfig.excludeFilters
                      }
                      allowedRange={
                        defaultCounterpartiesTableConfig.allowedRange
                      }
                      tokens={defaultCounterpartiesTableConfig.tokens}
                      setFilters={defaultCounterpartiesTableConfig.setFilters}
                    />
                  }
                  data={counterpartiesTable?.data?.counterparties || []}
                  isLoading={counterpartiesTable?.isLoading}
                  itemsPerPage={20}
                  config={counterpartyConfig}
                />
              )}
              {activeTab === 'transactions' && (
                <TransactionTable
                  filtersContent={
                    <TransactionFilters
                      counterpartyType={'cluster'}
                      filterType="default"
                      currency={clusterPageViewModel.blockchain}
                      filters={transactionsFilters}
                      initialFilters={toJS(
                        defaultTransactionsTableConfig.initFilters
                      )}
                      defaultFilters={toJS(
                        defaultTransactionsTableConfig.defaultFilters
                      )}
                      excludeFilters={
                        defaultTransactionsTableConfig.excludeFilters
                      }
                      tokens={defaultTransactionsTableConfig.tokens}
                      disabledAssetStaticSearch={
                        defaultTransactionsTableConfig.disabledAssetStaticSearch
                      }
                      onSearchAsset={
                        defaultTransactionsTableConfig.onSearchAsset
                      }
                      searchAssetLoading={
                        defaultTransactionsTableConfig.searchAssetLoading
                      }
                      filterSize="md"
                      getSearchResults={
                        defaultTransactionsTableConfig.getSearchClusters
                      }
                      allowedRange={defaultTransactionsTableConfig.allowedRange}
                      setFilters={defaultTransactionsTableConfig.setFilters}
                    />
                  }
                  data={transactionsTable?.data?.transactions || []}
                  isLoading={transactionsTable?.isLoading}
                  itemsPerPage={20}
                  config={transactionConfig}
                />
              )}
              {activeTab === 'addresses' && (
                <AddressTable
                  data={addressesTable?.data?.addresses || []}
                  isLoading={addressesTable?.isLoading}
                  itemsPerPage={20}
                  config={addressConfig}
                  filtersContent={
                    <AddressFilters
                      filters={addressesFilters}
                      initialFilters={toJS(
                        defaultAddressesTableConfig.initFilters
                      )}
                      defaultFilters={toJS(
                        defaultAddressesTableConfig.defaultFilters
                      )}
                      excludeFilters={
                        defaultAddressesTableConfig.excludeFilters
                      }
                      filterSize="md"
                      currency={defaultAddressesTableConfig.currency}
                      getSearchResults={
                        defaultAddressesTableConfig.getSearchResults
                      }
                      tokens={defaultAddressesTableConfig.tokens}
                      allowedRange={defaultAddressesTableConfig.allowedRange}
                      clusterId={defaultAddressesTableConfig.clusterId}
                      setFilters={defaultAddressesTableConfig.setFilters}
                    />
                  }
                />
              )}
              {activeTab === 'osint' && (
                <OsintTable
                  data={toJS(defaultOsintTableConfig.data)}
                  filters={toJS(defaultOsintTableConfig.filters)}
                  setFilters={defaultOsintTableConfig.setFilters}
                  isLoading={defaultOsintTableConfig.isLoading}
                  isVisitedLink={defaultOsintTableConfig.isVisitedLink}
                  visitedLinkAdd={defaultOsintTableConfig.visitedLinkAdd}
                  itemsPerPage={20}
                  currency={defaultOsintTableConfig.currency}
                />
              )}
            </div>
          ),
        }}
      </Portlet>
    </MagicGrid>
  )
}

export const ClusterEntitiesTable = observer(ClusterEntitiesTableComponent, {
  forwardRef: true,
})
