import { useTransactionAddressTableConfig } from './useTransactionAddressTableConfig'
import { Row, useTableSorting } from '@clain/core/ui-kit'
import { Checkbox } from '@clain/core/ui-kit'
import {
  getEvmTableConfig,
  TransactionAddressSelectedEvmTableProps,
} from '../TransactionAddressEvmTable'

export const useProbeTransactionAddressEvmTableConfig = (
  props: Omit<TransactionAddressSelectedEvmTableProps, 'itemsPerPage' | 'data'>
) => {
  const { config } = useTransactionAddressTableConfig(props) as unknown as {
    config: ReturnType<typeof getEvmTableConfig>
    sortingProps: ReturnType<typeof useTableSorting>
  }

  const selectedConfig = {
    ...config,
    item: {
      ...config.item,
      render: (item, index, options) => (
        <Row gap={0.75}>
          <Checkbox
            value={item.selected}
            isLoading={item.isProcessing}
            onChange={() => props.setSelected(item, !item.selected)}
          />
          {config.item.render(item, index, options)}
        </Row>
      ),
    },
  }
  return selectedConfig as typeof config
}
