import styled, { css } from 'styled-components'
import { Row } from '@clainio/web-platform'
import { RowAddressItemStyled } from '../components/RowAddressItem.styles'

export const RowAddressItemEVMStyled = styled(RowAddressItemStyled)<{
  direction?: 'left' | 'right'
}>`
  ${({ direction }) => css`
    padding-left: ${direction === 'left' ? '4px' : '8px'};
    padding-right: ${direction === 'left' ? '8px' : '4px'};
  `};
`

export const AssetChipCointainer = styled.div`
  overflow: hidden;
  width: 72px;
  max-width: 72px;
  flex: 0 0 72px;
`

export const AmountContainer = styled.div`
  flex: 1;
  display: inline-flex;
  align-items: center;
`

export const TagsContainerStyled = styled(Row)`
  width: 27px;
  min-width: 27px;
  white-space: nowrap;
  overflow: hidden;
  cursor: default;
`

export const AmountContainerStyled = styled.div`
  height: 32px;
  min-width: 120px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden;
`
export const AmountStyled = styled.div`
  overflow: hidden;
`
