import React, { memo } from 'react'
import classnames from 'classnames/bind'

import { Col, EllipsisTypography } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { Table } from '@clain/core/ui-kit'
import { Row } from '@clain/core/ui-kit'
import { CheckboxSwitch } from '@clain/core/ui-kit'
import { Score } from '@clain/core/ui-kit'
import { ConfirmButton } from '@clain/core/ui-kit'

import styles from './index.scss'
import { Tooltip } from '@clain/core/ui-kit'
import { Alerts, Alert, AlertEventsFilters } from '../../../../../modules'
import { AlertsCountById } from '../../../../../modules/alerts/AlertsViewModel.utils.types'
import { Counter } from '@clain/core/ui-kit'
import { Icon } from '@clain/core/ui-kit'
import { AlertDetails } from '../AlertDetails'
import { PopoverBaseContainer, TypographyNew } from '@clainio/web-platform'
import capitalizeFirstLetter from '@clain/core/utils/capitalizeFirstLetter'
import { BaseLink } from '@clain/core/Link'
import { withErrorBoundary } from '@clain/core/ErrorBoundary'

const cx = classnames.bind(styles)

type AlertWithKey = Alert & { key: string }

interface AlertsTableProps {
  alerts: Alerts
  counts: AlertsCountById
  toggleAlert: (index: number) => void
  removeAlert: (index: number) => void
  updateFilters: (filters: Partial<AlertEventsFilters>) => void
  loading: boolean
  loadingMain: boolean
  selectedAlertId: string
}

const AlertsTable: React.FC<AlertsTableProps> = ({
  alerts,
  toggleAlert,
  removeAlert,
  updateFilters,
  counts,
  loading,
  loadingMain,
  selectedAlertId,
}) => {
  const columns = [
    {
      name: 'tag',
      width: 1,
      render: (alert: AlertWithKey, index: number) => (
        <Row className={cx('TagCell')}>
          <Row gap={2}>
            <div>
              <Tooltip content="Funds Movement alert">
                <div>
                  <Icon
                    variant="FundsUpCircle"
                    color="onBackgroundVariant1"
                    size="sm"
                  />
                </div>
              </Tooltip>
            </div>
            <Row>
              <CheckboxSwitch
                value={alert.active}
                onChange={() => toggleAlert(index)}
                label={
                  <TypographyNew
                    variant="body100Normal"
                    color="onBackgroundBase"
                  >
                    {alert.tag}
                  </TypographyNew>
                }
              />
              <Counter
                value={counts[alert.id]?.seen}
                variant="alert"
                state="triggered"
              />
            </Row>
          </Row>
          <div className={cx('Delimiter')} />
        </Row>
      ),
    },
    {
      name: 'entity',
      width: 1,
      render: ({ cluster, address, type, currency }: AlertWithKey) => {
        const renderLink = () => {
          if (type === 'cluster') {
            return (
              <BaseLink
                to="/:currency/cluster/:clusterId"
                params={{ currency, clusterId: cluster.clusterId }}
                target="_blank"
                as={(props) => (
                  <EllipsisTypography
                    {...props}
                    as="a"
                    variant="body100NormalCode"
                    color="primaryBase"
                    type="center"
                  >
                    {cluster.entity?.name || cluster.clusterId}
                  </EllipsisTypography>
                )}
              />
            )
          }

          if (type === 'address') {
            return !cluster ? (
              address
            ) : (
              <BaseLink
                to="/:currency/address/:address"
                params={{ currency, address }}
                target="_blank"
                as={(props) => (
                  <EllipsisTypography
                    {...props}
                    as="a"
                    variant="body100NormalCode"
                    color="primaryBase"
                    type="center"
                  >
                    {address}
                  </EllipsisTypography>
                )}
              />
            )
          }
        }

        return (
          <Row gap={1} className={cx('Entity')} fullWidth>
            <div>
              <Icon variant={capitalizeFirstLetter(currency)} size="sm" />
            </div>
            <Row gap={0.5} fullWidth>
              <Score size="sm" value={cluster?.score || 0} />
              {renderLink()}
            </Row>
          </Row>
        )
      },
    },
    {
      name: 'conditions',
      width: 0.8,
      render: (alert: AlertWithKey) => {
        return (
          <>
            <TypographyNew variant="body100Normal" color="onBackgroundBase">
              {`${alert.direction === 'both' ? 'In/Out' : alert.direction} >= ${
                !alert.native ? '$' : ''
              }${alert.threshold}${
                alert.native
                  ? alert.asset
                    ? ` ${alert.asset?.symbol?.toLocaleUpperCase()}`
                    : ''
                  : ''
              }`}
            </TypographyNew>
            <div className={cx('Delimiter')} />
          </>
        )
      },
    },
    {
      name: 'actions',
      width: 0.6,
      align: 'right' as const,
      render: (alert: AlertWithKey, index: number) => (
        <Row>
          <PopoverBaseContainer
            placement="left"
            className={cx('Popup')}
            zIndex={1}
            content={<AlertDetails {...alert} key={alert.key} />}
            anchor={
              <div onClick={(e) => e.stopPropagation()}>
                <Tooltip content="Details">
                  <div>
                    <Icon
                      variant="Details"
                      color="onBackgroundVariant1"
                      size="sm"
                      cursor="pointer"
                    />
                  </div>
                </Tooltip>
              </div>
            }
          />
          <ConfirmButton
            confirmVariant="delete"
            onClick={(e) => {
              removeAlert(index)
              e.stopPropagation()
            }}
            title={
              <Typography
                variant="heading5"
                color="black"
                className={cx('RemoveAlert')}
              >
                Delete alert
              </Typography>
            }
            content={
              <Typography variant="body1" color="black">
                <Typography variant="body3" color="black">
                  {`${alert.tag} `}
                </Typography>
                will be deleted with its history of events all over the case.
              </Typography>
            }
          >
            <Tooltip content="Delete">
              <div>
                <Icon
                  variant="Delete"
                  color="onBackgroundVariant1"
                  size="sm"
                  cursor="pointer"
                />
              </div>
            </Tooltip>
          </ConfirmButton>
        </Row>
      ),
    },
  ]

  return (
    <Col className={cx('AlertsTable')}>
      <Table
        onRowClick={(alert: AlertWithKey) => {
          updateFilters({ alertId: alert.id })
        }}
        selectedRowIndex={alerts?.findIndex(
          (alert) => String(alert.id) === selectedAlertId
        )}
        columns={columns}
        variant="rounded-row"
        data={
          alerts?.length
            ? alerts.map((alert) => ({
                ...alert,
                key: String(alert.id),
              }))
            : []
        }
        rowHeight={72}
        headHeigh={48}
        className={cx('Table')}
        headClassName={cx('TableHead')}
        loading={loading}
        loadingMain={loadingMain}
      />
    </Col>
  )
}

export default withErrorBoundary(memo(AlertsTable))
