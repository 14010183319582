import { RowAddressItemProps } from '../evm/TransactionBlockEVM.types'
import { EllipsisTypography, Score, Tooltip, Tag } from '@clainio/web-platform'
import {
  AddressLabelWrapperStyled,
  AddressNameContainerStyled,
  HighlightedText,
  ClusterLabelWrapperStyled,
  RowAddressItemStyled,
  ScoreWrapperStyled,
  FoggyStyled,
} from './RowAddressItem.styles'
import { Link } from 'react-router-dom'
import { CopyIcon, EllipsisTypographyBlock } from '@clain/core/ui-kit'
import { isUTXO } from '@clain/core/types'

export const RowAddressItem = ({
  score,
  blockchain,
  address,
  clusterId,
  clusterName,
  leftChildren = null,
  rightChildren = null,
  highlighted = false,
  clusterWidthPercent,
  addressWidthPercent,
  addressMaxWidth,
  wrapper: Wrapper = RowAddressItemStyled,
}: RowAddressItemProps) => {
  return (
    <Wrapper>
      {leftChildren}
      <ScoreWrapperStyled>
        {score != null ? (
          <Score value={score} size={'xs'} />
        ) : (
          <Tag
            style={{ width: '100%' }}
            variant={'secondary'}
            size={'xs'}
            type={'highlight'}
          />
        )}
      </ScoreWrapperStyled>
      <AddressNameContainerStyled>
        <EllipsisTypographyBlock
          as={AddressLabelWrapperStyled}
          highlighted={highlighted}
          widthPercent={addressWidthPercent}
          maxWidth={addressMaxWidth}
        >
          <FoggyStyled
            color={isUTXO(blockchain) ? 'high' : 'base'}
            content={<CopyIcon value={address} />}
          >
            <Tooltip placement={'top-start'} content={address}>
              {address &&
                (clusterId == null ? (
                  <EllipsisTypography
                    type={'center'}
                    variant={'body200AccentCode'}
                    color={'onBackgroundBase'}
                  >
                    {address}
                  </EllipsisTypography>
                ) : (
                  <EllipsisTypography
                    as={Link}
                    to={`/${blockchain}/address/${address}`}
                    type={'center'}
                    variant={'body200AccentCode'}
                    color={'onBackgroundBase'}
                    variantAs="link"
                  >
                    {address}
                  </EllipsisTypography>
                ))}
            </Tooltip>
          </FoggyStyled>
          <HighlightedText> {address}</HighlightedText>
        </EllipsisTypographyBlock>
        <EllipsisTypographyBlock
          as={ClusterLabelWrapperStyled}
          widthPercent={clusterWidthPercent}
        >
          {clusterId != null ? (
            <FoggyStyled
              color={isUTXO(blockchain) ? 'high' : 'base'}
              content={<CopyIcon value={clusterName || String(clusterId)} />}
            >
              <Tooltip
                placement={'top-start'}
                content={clusterName || clusterId}
              >
                <EllipsisTypography
                  as={Link}
                  to={`/${blockchain}/cluster/${clusterId}`}
                  variant={'body200NormalCode'}
                  color={'onBackgroundVariant1'}
                  variantAs="link"
                >
                  {clusterName || String(clusterId)}
                </EllipsisTypography>
              </Tooltip>
            </FoggyStyled>
          ) : (
            <Tooltip placement={'top-start'} content={clusterName || clusterId}>
              <EllipsisTypography
                variant={'body200NormalCode'}
                color={'onBackgroundVariant1'}
              >
                {clusterId === 0 ? 'Mining' : ' Processing...'}
              </EllipsisTypography>
            </Tooltip>
          )}
        </EllipsisTypographyBlock>
      </AddressNameContainerStyled>
      {rightChildren}
    </Wrapper>
  )
}
