import styled, { css } from 'styled-components'
import { createTokenKey, Row, selectToken } from '@clainio/web-platform'
import { RowAddressItemStyled } from '@platform/components/TransactionBlock'

export const TagsContainerStyled = styled(Row)`
  width: 27px;
  min-width: 27px;
  white-space: nowrap;
  overflow: hidden;
  cursor: default;
`

export const FoggyWrapperAmountStyled = styled.div`
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    width: 10px;
    height: 100%;
    z-index: 1;
  }

  &::after {
    right: 0;
    background: linear-gradient(90deg, rgba(241, 243, 249, 0) 0%, white 100%);
  }
`

export const RowAddressItemEVMStyled = styled(RowAddressItemStyled)<{
  direction?: 'left' | 'right'
}>`
  ${({ theme, direction }) => css`
    padding-left: ${direction === 'left' ? '4px' : '8px'};
    padding-right: ${direction === 'left' ? '8px' : '4px'};

    &:hover {
      ${FoggyWrapperAmountStyled}::before {
        left: 0;
        background: linear-gradient(
          270deg,
          rgba(241, 243, 249, 0) 0%,
          ${selectToken(
              theme,
              createTokenKey(['color', 'secondary', 'container', 'base'])
            )}
            100%
        );
      }

      ${FoggyWrapperAmountStyled}::after {
        right: 0;
        background: linear-gradient(
          90deg,
          rgba(241, 243, 249, 0) 0%,
          ${selectToken(
              theme,
              createTokenKey(['color', 'secondary', 'container', 'base'])
            )}
            100%
        );
      }
    }
  `};
`

export const AssetChipContainer = styled.div`
  max-width: 72px;
  width: 72px;
  display: inline-flex;
`

export const AmountContainerStyled = styled.div`
  height: 32px;
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden;
`
export const AmountStyled = styled.div`
  overflow: hidden;
`

export const AmountUsdContainerStyled = styled.div`
  height: 32px;
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden;
`

export const AmountUSDStyled = styled.div`
  overflow: hidden;
`
