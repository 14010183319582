import React from 'react'
import {
  CopyIcon,
  EllipsisTypography,
  EllipsisTypographyBlock,
  Foggy,
  Row,
  Stack,
  Tooltip,
  TypographyNew,
} from '@clain/core/ui-kit'
import { BaseLink } from '@clain/core/Link'
import fromUnixTime from 'date-fns/fromUnixTime'

import { TableSortButton, Amount } from '@clain/core/ui-kit'
import { AssetsListShort } from '@clain/core/ui-kit'

import classnames from 'classnames/bind'
import styles from '../AddressTable.scss'

import AssetsRow from '../../../../ProbeSandbox/ui/AssetsRow'

import {
  AddressUtxotableItem,
  AddressUtxoTableKey,
  DefaultUtxoTableConfig,
  TableColumnConfig,
} from './AddressUtxoTable.types'
import { ADDRESS_TYPE } from '../../../../ProbeSandbox/types/converted/ClusterAddress'
import { AddressFeatureTags } from '../../../../ProbeSandbox/ui/FeautureTags'
import { PopoverBaseContainer } from '@clainio/web-platform'
import {
  DEFAULT_CURRENCY_TOKEN_ID,
  isDefaultTokenId,
} from '../../../../ProbeSandbox/utils/convertTokenBalances'
import { formatMoney, GENERAL_NUMBER_NOTATION } from '@clain/core/utils/format'

const cx = classnames.bind(styles)

export const getUtxoTableConfig = ({
  filters,
  isLoading,
  onChangeSort,
  showInUSD,
  formatMoney: formatMoneySettings,
  formatDate,
  currency,
}: DefaultUtxoTableConfig<AddressUtxotableItem>) => {
  const getAmountConfig = (
    amount: number | string,
    amountUsd: number | string
  ) => {
    return showInUSD || filters?.includeTokens?.[0]?.id == null
      ? {
          value: amountUsd,
          currency: 'usd',
          code: 'USD',
        }
      : DEFAULT_CURRENCY_TOKEN_ID.includes(filters?.includeTokens?.[0]?.id)
      ? {
          value: amount,
          currency,
        }
      : {
          value: amount,
          decimals: filters?.includeTokens[0].decimals,
          code: filters?.includeTokens[0].symbol,
        }
  }

  const defaultConfig: Record<
    AddressUtxoTableKey,
    TableColumnConfig<AddressUtxotableItem>
  > = {
    address: {
      name: 'address',
      width: 1.5,
      renderTitle: () => (
        <Row gap={0.5}>
          <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
            Address
          </TypographyNew>
        </Row>
      ),
      render: (item) => {
        const noAddress =
          item.type === ADDRESS_TYPE.NS || item.type === ADDRESS_TYPE.PKH_MULT

        return (
          <>
            {noAddress ? (
              <Row gap={0.5}>
                <TypographyNew
                  variant="body200NormalCode"
                  color="onBackgroundVariant2"
                >
                  Unable to decode output address
                </TypographyNew>
              </Row>
            ) : (
              <Row className={cx('AddressRow')}>
                <EllipsisTypographyBlock as={Stack} fullWidth>
                  <Foggy
                    color="base"
                    content={<CopyIcon value={item.address} />}
                  >
                    <Tooltip content={item.address}>
                      <span style={{ width: '100%', display: 'inline-flex' }}>
                        <BaseLink
                          to="/:coin/address/:address"
                          params={{ address: item.address, coin: currency }}
                          target="_blank"
                          as={(props) => (
                            <EllipsisTypography
                              {...props}
                              as="a"
                              variant="body200NormalCode"
                              color="onBackgroundBase"
                              type="center"
                            >
                              {item.address}
                            </EllipsisTypography>
                          )}
                        />
                      </span>
                    </Tooltip>
                  </Foggy>
                </EllipsisTypographyBlock>
              </Row>
            )}
          </>
        )
      },
    },
    features: {
      name: 'features',
      width: 0.5,
      minWidth: '140px',
      align: 'left',
      renderTitle: () => (
        <Row gap={0.5}>
          <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
            Features
          </TypographyNew>
        </Row>
      ),
      render: (address) => <AddressFeatureTags address={address} />,
    },
    tokens: {
      name: 'tokens',
      width: 0.5,
      align: 'center',
      renderTitle: () => (
        <Row gap={0.5}>
          <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
            Assets
          </TypographyNew>
        </Row>
      ),
      render: ({ tokens }) => (
        <PopoverBaseContainer
          maxHeight={300}
          content={
            <AssetsListShort
              assets={tokens}
              isDefaultTokenId={isDefaultTokenId}
              size="sm"
              useNewIcons
            />
          }
          anchor={
            <span>
              <AssetsRow
                className={cx('AssetsRow')}
                buttonMode
                tokens={tokens}
              />
            </span>
          }
        />
      ),
    },
    received: {
      name: 'received',
      width: 1,
      align: 'right',
      renderTitle: () => (
        <Row gap={0.5}>
          <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
            Received
          </TypographyNew>
          <TableSortButton
            name="received"
            onChangeSort={onChangeSort}
            order={filters.sortOrder}
            sortBy={filters.sortBy}
            disabled={isLoading}
          />
        </Row>
      ),
      render: ({ received, receivedUsd }) => {
        if (!received) return

        const formatReceivedConfig = getAmountConfig(received, receivedUsd)

        return (
          <Amount
            variant="body200NormalCode"
            color="onBackgroundBase"
            value={formatMoneySettings({
              ...formatReceivedConfig,
              formats: GENERAL_NUMBER_NOTATION,
            })}
            fullValue={formatMoney({
              ...formatReceivedConfig,
              precision: 64,
              minimumSignificantDigits: 1,
            })}
            copyValue={formatMoney({
              ...formatReceivedConfig,
              precision: 64,
              code: '',
              minimumSignificantDigits: 1,
            })}
            enabledCopy
          />
        )
      },
    },
    sent: {
      name: 'sent',
      width: 1,
      align: 'right',
      renderTitle: () => (
        <Row gap={0.5}>
          <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
            Sent
          </TypographyNew>
          <TableSortButton
            name="sent"
            onChangeSort={onChangeSort}
            order={filters.sortOrder}
            sortBy={filters.sortBy}
            disabled={isLoading}
          />
        </Row>
      ),
      render: ({ sent, sentUsd }) => {
        if (!sent) return

        const formatSentConfig = getAmountConfig(sent, sentUsd)

        return (
          <Amount
            variant="body200NormalCode"
            color="onBackgroundBase"
            value={formatMoneySettings({
              ...formatSentConfig,
              formats: GENERAL_NUMBER_NOTATION,
            })}
            fullValue={formatMoney({
              ...formatSentConfig,
              precision: 64,
              minimumSignificantDigits: 1,
            })}
            copyValue={formatMoney({
              ...formatSentConfig,
              precision: 64,
              code: '',
              minimumSignificantDigits: 1,
            })}
            enabledCopy
          />
        )
      },
    },
    balance: {
      name: 'balance',
      width: 1,
      align: 'right',
      renderTitle: () => (
        <Row gap={0.5}>
          <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
            Balance
          </TypographyNew>
          <TableSortButton
            name="balance"
            onChangeSort={onChangeSort}
            order={filters.sortOrder}
            sortBy={filters.sortBy}
            disabled={isLoading}
          />
        </Row>
      ),
      render: ({ balance, balanceUsd }) => {
        if (!balance) return

        const formatBalanceConfig = getAmountConfig(balance, balanceUsd)

        return (
          <Amount
            variant="body200NormalCode"
            color="onBackgroundBase"
            value={formatMoneySettings({
              ...formatBalanceConfig,
              formats: GENERAL_NUMBER_NOTATION,
            })}
            fullValue={formatMoney({
              ...formatBalanceConfig,
              precision: 64,
              minimumSignificantDigits: 1,
            })}
            copyValue={formatMoney({
              ...formatBalanceConfig,
              precision: 64,
              code: '',
              minimumSignificantDigits: 1,
            })}
            enabledCopy
          />
        )
      },
    },
    transactions: {
      name: 'transactions',
      width: 1,
      align: 'right',
      renderTitle: () => (
        <Row gap={0.5}>
          <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
            Transactions
          </TypographyNew>
          <TableSortButton
            name="transactions"
            onChangeSort={onChangeSort}
            order={filters.sortOrder}
            sortBy={filters.sortBy}
            disabled={isLoading}
          />
        </Row>
      ),
      render: ({ trx, trxIn, trxOut }) => (
        <TypographyNew variant="body200Normal" color="onBackgroundBase">
          {trx} (
          <TypographyNew
            as="span"
            variant="body200Normal"
            color="onBackgroundVariant2"
          >
            In
          </TypographyNew>{' '}
          {trxIn}/{' '}
          <TypographyNew
            as="span"
            variant="body200Normal"
            color="onBackgroundVariant2"
          >
            Out
          </TypographyNew>{' '}
          {trxOut})
        </TypographyNew>
      ),
    },
    period: {
      name: 'period',
      width: 1.25,
      minWidth: '220px',
      align: 'right',
      renderTitle: () => (
        <Row gap={0.5}>
          <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
            Activity Period
          </TypographyNew>
          <TableSortButton
            name="time"
            onChangeSort={onChangeSort}
            order={filters.sortOrder}
            sortBy={filters.sortBy}
            disabled={isLoading}
          />
        </Row>
      ),
      render: ({ activeFrom, activeTo }) => {
        if (!activeFrom || !activeTo)
          return (
            <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
              unknown
            </TypographyNew>
          )

        return (
          <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
            {`${formatDate(
              fromUnixTime(activeFrom),
              'date-numeric'
            )} - ${formatDate(fromUnixTime(activeTo), 'date-numeric')}`}
          </TypographyNew>
        )
      },
    },
  }

  return defaultConfig
}
