import {
  TransactionAddressUtxotableItem,
  AddressUtxoTableKey,
  DefaultUtxoTableConfig,
  TableColumnConfig,
} from './TransactionAddressUtxoTable.types'
import { Row, TableSortButton, TypographyNew } from '@clainio/web-platform'
import { AddressItem } from '../../../../TransactionBlock/utxo/AddressItem'
import {
  isInputTransaction,
  isOutputTransaction,
} from '../../../../TransactionBlock/utxo/TransactionBlockUTXO.utils'
import { AmountBlock } from '../../../../TransactionBlock/utxo/AmountBlock'
import * as S from './TransactionAddressUtxoTable.styles'
import { Stack } from '@clain/core/ui-kit'

export const getUtxoTableConfig = <T extends TransactionAddressUtxotableItem>({
  showInUSD,
  type,
  currency,
  sortingProps,
  isActive,
  transactionTagsProperties,
}: DefaultUtxoTableConfig<T>) => {
  const defaultConfig: Record<AddressUtxoTableKey, TableColumnConfig<T>> = {
    ...(type === 'outflow'
      ? {
          emptyLeftCell: {
            name: 'emptyLeftCell',
            width: 0,
            renderTitle: () => null,
            render: () => null,
          },
        }
      : {}),
    address: {
      name: 'address',
      align: 'left',
      width: 3,
      renderTitle: () => (
        <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
          {type === 'inflow' ? 'Input' : 'Output'}
        </TypographyNew>
      ),
      render: (address) => {
        if (isInputTransaction(address)) {
          return (
            <AddressItem
              {...address}
              transactionTagsProperties={transactionTagsProperties}
              highlighted={isActive?.(address)}
              withPaddings={false}
              isFullWidth
              key={address.inputId}
              blockchain={currency}
            />
          )
        }
        if (isOutputTransaction(address)) {
          return (
            <AddressItem
              {...address}
              highlighted={isActive?.(address)}
              withPaddings={false}
              isFullWidth
              key={address.outputId}
              blockchain={currency}
            />
          )
        }
        return null
      },
    },
    amount: {
      name: 'amount',
      align: 'right',
      width: 1,
      renderTitle: () => (
        <Row gap={0.75}>
          <TypographyNew variant="body200NormalCode" color="onBackgroundBase">
            Amount
          </TypographyNew>
          {sortingProps.type === 'controlled' ? (
            <TableSortButton
              name="amount"
              sortBy={
                type === 'inflow'
                  ? sortingProps.inputSortBy
                  : sortingProps.outputSortBy
              }
              order={
                type === 'inflow'
                  ? sortingProps.inputSortOrder
                  : sortingProps.outputSortOrder
              }
              onChangeSort={(field) =>
                sortingProps.onChangeSort(
                  type === 'inflow' ? 'input' : 'output',
                  field
                )
              }
            />
          ) : (
            <TableSortButton name="amount" {...sortingProps} />
          )}
        </Row>
      ),
      render: ({ amount, amountUsd, coinbase }) => {
        if (coinbase) return null

        const currencyType = showInUSD ? 'usd' : 'src'

        return (
          <S.AddressContainer>
            <AmountBlock
              amount={amount}
              currency={currencyType}
              amountUSD={amountUsd}
              blockchain={currency}
            />
          </S.AddressContainer>
        )
      },
    },
    ...(type === 'inflow'
      ? {
          emptyRightCell: {
            name: 'emptyRightCell',
            width: 0,
            renderTitle: () => null,
            render: () => null,
          },
        }
      : {}),
  }

  return defaultConfig
}
