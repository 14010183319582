import { action, computed, makeObservable, observable } from 'mobx'
import { injectable } from 'inversify'

import type {
  IconSatellite,
  NodeAttributes,
  PillsSatellite,
} from '@clain/graph'

import ProbeNode from './ProbeNode'
import { icon } from '@clainio/web-platform/dist/components/Icon/iconFn'

import {
  createCrossChainAction,
  createDemixActionAddon,
  createNodeCreateAtLabel,
} from './ProbeNode.utils'
import { Position } from '@clain/graph-layout/types'
import { NodeSettings, TransactionNodeDataUtxo } from '../../types'
import {
  ILayers,
  ILayoutSettingsState,
  IProbeGraph,
  IProbeState,
  ITheme,
  ITransactionProbeNodeBase,
} from '../../models'
import getFeatureTagColor from '@clain/core/utils/getFeatureTagColor'

const CORE_RADIUS = 5
const CORE_BACKGROUND_COLOR = 'rgba(55, 71, 102, 1)'
const CORE_BACKGROUND_COLOR_HIGHLIGHTED = 'rgba(33, 115, 255, 1)'
const MULTI_TRX_ORBIT_SIZE = 12
const PILLS_ORBIT_SIZE = 14
const PILLS_BACKGROUND_COLOR = 'rgba(141, 158, 193, 1)'
const GHOSTED_OPACITY = 0.3
const MEMPOOL_ORBIT_SIZE = 24
const TRX_TIMESTAMP_ORBIT_SIZE = 14

@injectable()
export class TransactionProbeNodeUtxo<
    T extends TransactionNodeDataUtxo = TransactionNodeDataUtxo
  >
  extends ProbeNode<T>
  implements ITransactionProbeNodeBase
{
  @observable public demixActionHovered: boolean
  @observable public crossSwapActionHovered: boolean

  constructor(
    theme: ITheme,
    layers: ILayers,
    layoutSettingsState: ILayoutSettingsState,
    probeState: IProbeState,
    graph: IProbeGraph,
    id: string,
    data: T,
    position: Position,
    settings?: NodeSettings
  ) {
    super(
      theme,
      layers,
      layoutSettingsState,
      probeState,
      graph,
      id,
      data,
      position,
      settings
    )
    makeObservable(this)
  }

  @action
  public setDemixActionHovered(isHovered: boolean) {
    this.demixActionHovered = isHovered
  }

  @action
  public setCrossSwapActionHovered(isHovered: boolean) {
    this.crossSwapActionHovered = isHovered
  }

  @computed
  get multiOutputs(): boolean {
    const edges = this.probeState.getEdges

    return (
      this.data.outputs &&
      edges.filter((edge) => edge.sourceKey === this.key).length !==
        this.data.outputs.filter(({ type }) => type !== 'ND').length
    )
  }

  @computed
  get multiInputs(): boolean {
    const edges = this.probeState.getEdges

    return (
      this.data.inputs &&
      edges.filter((edge) => edge.targetKey === this.key).length !==
        this.data.inputs.filter(({ type }) => type !== 'ND').length
    )
  }

  protected generateAttributes() {
    const isMultiTrx = this.multiOutputs || this.multiInputs
    const iconSize = parseInt(this.theme.getToken(['icon', 'xxs', 'size']))
    const iconColor = this.theme.getToken([
      'icon',
      'on',
      'background',
      'variant1',
      'color',
    ])

    const attributes = {
      size: CORE_RADIUS,
      increaseHitArea: 16,
      fill: CORE_BACKGROUND_COLOR,
      opacity: this.ghosted ? GHOSTED_OPACITY : undefined,
      shape: 'circle',
      locked: this.locked === true ? true : false,
      withPills: this.layers.trxFeatures,
      linkType: 'master',
      orbits: [
        {
          size: MULTI_TRX_ORBIT_SIZE,
          locations: [
            {
              angle: Math.PI * 0.5,
              satellite: {
                type: 'icon',
                id: 'multiTrx',
                width: iconSize,
                height: iconSize,
                color: iconColor,
              },
            },
          ],
          virtual: true,
        },
        {
          size: PILLS_ORBIT_SIZE,
          locations: [
            {
              angle: Math.PI * 1.5,
              satellite: {
                type: 'pills',
                id: 'pills',
              },
            },
          ],
          virtual: true,
        },
        {
          size: MEMPOOL_ORBIT_SIZE,
          locations: [
            {
              angle: Math.PI * 0.5,
              satellite: {
                type: 'label',
              },
            },
          ],
          virtual: true,
        },
        {
          size: this.data.memPool
            ? isMultiTrx
              ? -(
                  MEMPOOL_ORBIT_SIZE +
                  MULTI_TRX_ORBIT_SIZE +
                  TRX_TIMESTAMP_ORBIT_SIZE
                )
              : -(MEMPOOL_ORBIT_SIZE + TRX_TIMESTAMP_ORBIT_SIZE + 10)
            : isMultiTrx
            ? -(MULTI_TRX_ORBIT_SIZE + TRX_TIMESTAMP_ORBIT_SIZE)
            : -(TRX_TIMESTAMP_ORBIT_SIZE + 4),
          locations: [
            {
              angle: -Math.PI / 2,
              satellite: {
                id: 'createAt',
                type: 'label',
              },
            },
          ],
          virtual: true,
        },
      ],
    } as NodeAttributes<T>

    if (this.highlighted) {
      attributes.fill = CORE_BACKGROUND_COLOR_HIGHLIGHTED
    }

    if (this.data?.demixTracks?.length && this.data.crossSwap) {
      attributes.orbits.push({
        size: MEMPOOL_ORBIT_SIZE + 22,
        locations: [
          {
            angle: -Math.PI / 1.5,
            satellite: createCrossChainAction(
              this.crossSwapActionHovered,
              this.theme
            ),
          },
        ],
        virtual: true,
      })
      attributes.orbits.push({
        size: MEMPOOL_ORBIT_SIZE + 25,
        locations: [
          {
            angle: -Math.PI / 3.3,
            satellite: {
              id: 'demixAction',
              type: 'label',
              fontSize: 12,
              color: 'rgba(15, 19, 27, 1)',
              ...createDemixActionAddon({
                theme: this.theme,
                count: this.data?.demixTracks?.length || 0, // TODO use this.data to achieve real count
                isHovered: this.demixActionHovered,
              }),
            },
          },
        ],
        virtual: true,
      })
    } else if (this.data?.demixTracks?.length || this.data.crossSwap) {
      attributes.orbits.push({
        size: MEMPOOL_ORBIT_SIZE + 14,
        locations: [
          {
            angle: -Math.PI / 2,
            satellite: this.data.crossSwap
              ? createCrossChainAction(this.crossSwapActionHovered, this.theme)
              : {
                  id: 'demixAction',
                  type: 'label',
                  fontSize: 12,
                  color: 'rgba(15, 19, 27, 1)',
                  ...createDemixActionAddon({
                    theme: this.theme,
                    count: this.data?.demixTracks?.length || 0, // TODO use this.data to achieve real count
                    isHovered: this.demixActionHovered,
                  }),
                },
          },
        ],
        virtual: true,
      })
    }

    if (isMultiTrx) {
      ;(attributes.orbits[0].locations[0].satellite as IconSatellite).icon =
        icon({ variant: 'moreHorizontal' })
    }

    if (this.data.memPool) {
      attributes.orbits[2].locations[0].satellite = {
        type: 'label',
        fontSize: 9,
        color: 'rgba(75, 96, 138, 1)',
        text: 'MEMPOOL',
        fill: 'rgba(219, 226, 240, 1)',
        borderRadius: 4,
        border: {
          color: 'rgba(141, 158, 193, 1)',
          width: 2,
        },
        padding: [4, 2],
      }
    }

    if (this.layers.trxTimestamp && this.data.time) {
      attributes.orbits[3].locations[0].satellite = {
        id: 'createAt',
        type: 'label',
        ...createNodeCreateAtLabel({
          date: this.data.time,
          timezone: this.userSettingsTimezone,
        }),
      }
    }

    if (this.layers.trxFeatures) {
      const satellite = attributes.orbits[1].locations[0]
        .satellite as PillsSatellite

      satellite.fill = PILLS_BACKGROUND_COLOR
      satellite.parts = []

      if (this.data.token) {
        satellite.parts.push({
          fill: getFeatureTagColor('OMNI'),
        })
      }

      if (this.data.version) {
        satellite.parts.push({
          fill: getFeatureTagColor(this.data.version === 1 ? 'V1' : 'V2'),
        })
      }

      if (this.data.lockTime) {
        satellite.parts.push({ fill: getFeatureTagColor('LT') })
      }

      if (this.data.rbf) {
        satellite.parts.push({ fill: getFeatureTagColor('RBF') })
      }

      if (this.data.segwit) {
        satellite.parts.push({ fill: getFeatureTagColor('SEGWIT') })
      }
    }
    return attributes
  }
}
