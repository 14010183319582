import React, { memo } from 'react'
import classnames from 'classnames/bind'
import { observer } from 'mobx-react-lite'

import { capitalizeFirstLetter, Icon, Typography } from '@clain/core/ui-kit'
import { Modal } from '@clain/core/ui-kit'
import { Button } from '@clain/core/ui-kit'
import { Row } from '@clain/core/ui-kit'
import Form, { Field, validators } from '@clain/core/Form'
import { TextField } from '@clain/core/ui-kit'
import { Label } from '@clain/core/ui-kit'
import { Col } from '@clain/core/ui-kit'
import { ButtonSelect } from '@clain/core/ui-kit'
import { Score } from '@clain/core/ui-kit'

import { ReactComponent as CloseIcon } from '@clain/core/assets/close.svg'

import styles from './CreateAlertModal.scss'
import {
  EntityAutocompleteValue,
  CreateAlertModalProps,
  CreateAlertFormKeys,
  CreateAlertForm,
} from './CreateAlertModal.types'
import {
  CREATE_ALERT_CURRENCY,
  CREATE_ALERT_DIRECTION,
  CREATE_ALERT_TRIGGER_FREQUENCY_OPTIONS,
} from './CreateAlertModal.constants'

import { validateNumber } from '@clain/core/utils/tools'
import { omit } from 'ramda'
import { NewAlert } from '../../../../../modules'
import { selectByKeys } from '@clain/core/utils'
import {
  createAlertInitialValues,
  currencyText,
} from './CreateAlertModal.utils'
import { EntityAutocomplete } from './EntityAutocomplete'
import { CreateAlertTokenFilter } from './CreateAlertTokenFilter'
import { notify } from '@clain/core/ui-kit'
import { CoinType } from '../../../../../types/coin'

const cx = classnames.bind(styles)

const FieldThreshold: React.FC<{ currecy: string; disabled: boolean }> = memo(
  ({ currecy, disabled }) => (
    <Field
      fullWidth
      name="threshold"
      as={({
        value,
        onChange,
      }: {
        value: string
        onChange: (value: string) => void
      }) => (
        <TextField
          variant="outline"
          value={value}
          name="threshold"
          placeholder={`${currecy ? currecy.toUpperCase() : ''} >= 0`}
          disabled={disabled}
          onChange={(value) => {
            if (validateNumber(value)) {
              onChange(value)
            }
          }}
        />
      )}
    />
  )
)

FieldThreshold.displayName = 'FieldThreshold'

export const CreateAlertModal: React.FC<CreateAlertModalProps> = observer(
  (props) => {
    const [formError, setFormError] = React.useState()

    return (
      <Modal
        target={props.target}
        className={cx('CreateAlertModal')}
        preventScroll={true}
        visible={props?.visible}
        onRequestClose={props?.onRequestClose}
      >
        {({ hide }) => {
          const onFormSubmit = async (
            data: NewAlert &
              CreateAlertForm & {
                item: EntityAutocompleteValue & {
                  currency: CoinType
                }
              }
          ) => {
            setFormError(undefined)
            try {
              let finalData: NewAlert
              const tokenId = data.asset.isBaseToken ? 0 : data.asset.id
              if (props.type === 'probe') {
                finalData =
                  props.nodeType === 'address'
                    ? {
                        ...omit(['item'], data),
                        currency: props.currency,
                        tokenId,
                        address: `${props.id}`,
                      }
                    : {
                        ...omit(['item', 'asset'], data),
                        currency: props.currency,
                        tokenId,
                        cluster: props.id,
                      }
              } else {
                finalData = data.item.aid
                  ? {
                      ...data,
                      currency: data.item.currency,
                      tokenId,
                      address: data.item.address,
                    }
                  : {
                      ...data,
                      currency: data.item.currency,
                      tokenId,
                      cluster: data.item.cluster_id,
                    }
              }

              await props.onSubmit(finalData)
              notify(
                `Alert ${selectByKeys(
                  CreateAlertFormKeys.TAG,
                  finalData
                )} is successfully created`,
                {
                  type: 'success',
                }
              )
              hide()
              props?.onRequestClose?.({} as any)
            } catch (data) {
              setFormError(data.error)
            }
          }

          return (
            <React.Fragment>
              <Row align="between" className={cx('Header')}>
                <Row align="between" gap={props.type === 'probe' ? 1 : 0}>
                  <Typography variant="heading5" color="black">
                    Add alert
                  </Typography>
                </Row>
                <CloseIcon
                  className={cx('CloseIcon')}
                  onClick={(e) => {
                    hide()
                    props?.onRequestClose?.(e)
                  }}
                />
              </Row>
              <Form
                className={cx('NewAlertForm')}
                initialValues={createAlertInitialValues()}
                onSubmit={onFormSubmit}
              >
                {({ form }) => {
                  const { valid, values } = form.getState()

                  return (
                    <Col gap={2}>
                      <Row gap={1.5}>
                        <Label
                          content={
                            props.type === 'probe'
                              ? props.nodeType === 'cluster'
                                ? 'Cluster'
                                : 'Address'
                              : 'Cluster name or Address (required)'
                          }
                          className={cx('Search')}
                          color="grey1"
                        >
                          {props.type === 'probe' ? (
                            <Row
                              align="left"
                              gap={1}
                              className={cx('ProbeInfoRow')}
                            >
                              <Icon
                                variant={capitalizeFirstLetter(props.currency)}
                                size="sm"
                              />
                              <Row gap={0.5} className={cx('ProbeInfo')}>
                                <Score
                                  value={props.score}
                                  useNewColors={true}
                                  size="sm"
                                />
                                <Typography
                                  variant="body1"
                                  color="black"
                                  ellipsis
                                >
                                  {props.entities}
                                </Typography>
                              </Row>
                            </Row>
                          ) : (
                            <Field
                              fullWidth
                              name="item"
                              variant="outline"
                              as={EntityAutocomplete}
                              validate={validators.required()}
                            />
                          )}
                        </Label>
                        <Label
                          content="Asset"
                          className={cx('Asset')}
                          color="grey1"
                          as="div"
                        >
                          <CreateAlertTokenFilter
                            currency={
                              props.type === 'probe'
                                ? props.currency
                                : selectByKeys(
                                    CreateAlertFormKeys.ITEM_CURRENCY,
                                    values
                                  )
                            }
                            value={
                              selectByKeys(CreateAlertFormKeys.ASSET, values)
                                ?.id
                            }
                            onChange={(value) => {
                              form?.change(CreateAlertFormKeys.ASSET, value)
                            }}
                            disabled={
                              props.type === 'case' &&
                              !selectByKeys(CreateAlertFormKeys.ITEM, values)
                            }
                            placeholder="Asset"
                          />
                        </Label>
                      </Row>
                      <Row gap={1.5}>
                        <Label
                          content="Label (required)"
                          className={cx('Description')}
                          color="grey1"
                        >
                          <Field
                            fullWidth
                            name="tag"
                            variant="outline"
                            as={TextField}
                            placeholder="Alert description up to 70 characters"
                            validate={validators.required()}
                          />
                        </Label>
                        <Label
                          content="Trigger"
                          className={cx('TriggerFrequency')}
                          color="grey1"
                        >
                          <Field
                            fullWidth
                            name="recurrent"
                            variant="outline"
                            as={({ value, onChange }) => (
                              <ButtonSelect
                                fullWidth
                                value={value}
                                onChange={onChange}
                                options={CREATE_ALERT_TRIGGER_FREQUENCY_OPTIONS}
                              />
                            )}
                            validate={validators.required()}
                          />
                        </Label>
                      </Row>

                      <Row gap={1.5}>
                        <Label
                          content="Direction"
                          className={cx('Direction')}
                          color="grey1"
                        >
                          <Field
                            fullWidth
                            name="direction"
                            variant="outline"
                            as={({ value, onChange }) => (
                              <ButtonSelect
                                fullWidth
                                value={value}
                                onChange={onChange}
                                options={CREATE_ALERT_DIRECTION}
                              />
                            )}
                            placeholder="Suspicious activity"
                            validate={validators.required()}
                          />
                        </Label>
                        <Label
                          content="Threshold"
                          className={cx('Threshold')}
                          color="grey1"
                        >
                          <FieldThreshold
                            currecy={currencyText(props, values)}
                            disabled={
                              props.type === 'case' &&
                              !selectByKeys(CreateAlertFormKeys.ITEM, values)
                            }
                          />
                        </Label>
                        <Label
                          content="Currency"
                          className={cx('Currency')}
                          color="grey1"
                        >
                          <Field
                            fullWidth
                            name="native"
                            variant="outline"
                            as={({ value, onChange }) => (
                              <ButtonSelect
                                fullWidth
                                value={value}
                                onChange={onChange}
                                options={CREATE_ALERT_CURRENCY}
                              />
                            )}
                            validate={validators.required()}
                          />
                        </Label>
                      </Row>
                      <Row className={cx('FormButtons')}>
                        <Button
                          size="lg"
                          variant="outline"
                          color="secondary"
                          onClick={(e) => {
                            hide()
                            props?.onRequestClose?.(
                              e as unknown as React.MouseEvent<
                                Element,
                                MouseEvent
                              >
                            )
                          }}
                        >
                          cancel
                        </Button>
                        <Button
                          disabled={!valid}
                          size="lg"
                          variant="solid"
                          color="primary"
                          type="submit"
                        >
                          add alert
                        </Button>
                      </Row>
                      {formError && (
                        <Typography className={cx('Error')} color="magenta">
                          {JSON.stringify(formError)}
                        </Typography>
                      )}
                    </Col>
                  )
                }}
              </Form>
            </React.Fragment>
          )
        }}
      </Modal>
    )
  }
)
