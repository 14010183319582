import { observable, action, computed, makeObservable } from 'mobx'
import {
  BlockTransactionEVM,
  BlockTransactionUTXO,
  BlockDataTransaction,
} from '@platform/apiServices'
import { BlocksHeightState } from '@platform/states/BlocksHeightState'
import {
  PlotEntitiesController,
  plotEntitiesController,
} from '@platform/components/ProbeSandbox/vm/PlotEntitiesController'
import { ctx } from '@platform/ctx'
import { createProbePath } from '@platform/constants'
import {
  LiteTransactionAddressUtxo,
  EventAddNodeData,
} from '@clain/graph-entities'
import { isUTXO } from '@clain/core/types'

export class PlotTransactionsOnGraph<
  TTransaction extends BlockDataTransaction = BlockDataTransaction
> {
  @observable private _selectedTransactions: TTransaction[] = []
  private blocksHeightState: BlocksHeightState
  private plotEntitiesController: PlotEntitiesController
  @observable private transactions: Array<TTransaction> = []

  constructor() {
    makeObservable(this)
    this.blocksHeightState = ctx.blocksHeightState
    this.plotEntitiesController = plotEntitiesController
  }

  @action
  public init = ({ transactions }: { transactions: Array<TTransaction> }) => {
    this.transactions = transactions
  }

  @action
  public clear = () => {
    this.resetSelectedTransactions()
    this.transactions = []
  }

  @computed
  public get selectedTransactions() {
    return this._selectedTransactions
  }

  @computed
  public get selectedTransactionsCount() {
    return this._selectedTransactions.length
  }

  @action
  public setSelectTransactions = (data: TTransaction, checked: boolean) => {
    if (checked) {
      this._selectedTransactions = [...this._selectedTransactions, data]
    } else {
      this._selectedTransactions = this._selectedTransactions.filter(
        (transaction) => transaction.id !== data.id
      )
    }
  }

  @computed.struct
  public get disabledPlotOnGraph() {
    return !this._selectedTransactions.length
  }

  @action
  public plotSelectedTransactionsOnGraph = () => {
    if (!this.disabledPlotOnGraph) {
      const normalizedAddUtxoTransactions = this._selectedTransactions.map(
        (_transaction) => {
          if (isUTXO(_transaction.currency)) {
            const transaction = _transaction as unknown as BlockTransactionUTXO

            return {
              createBy: 'by-trx-id',
              strategy: 'transaction',
              type: 'input',
              currency: transaction.currency,
              id: transaction.id,
              hash: transaction.hash,
              direction: 'out',
              inputs:
                transaction.inputs as unknown as LiteTransactionAddressUtxo[],
              outputs:
                transaction.outputs as unknown as LiteTransactionAddressUtxo[],
            }
          } else {
            const transaction = _transaction as BlockTransactionEVM

            return {
              strategy: 'transaction',
              type: 'transfer',
              currency: transaction.currency,
              index: 0,
              from: {
                hash: transaction.transfers[0]?.sender.address,
                id: transaction.transfers[0]?.sender.addressId,
                clusterId: transaction.transfers[0]?.sender.clusterId,
              },
              to: {
                hash: transaction.transfers[0]?.receiver.address,
                id: transaction.transfers[0]?.receiver.addressId,
                clusterId: transaction.transfers[0]?.receiver.clusterId,
              },
              hash: transaction.hash,
              id: transaction.id,
            }
          }
        }
      ) as EventAddNodeData[]

      this.plotEntitiesOnGraph(normalizedAddUtxoTransactions)
    }
  }

  @action
  private plotEntitiesOnGraph = (
    entities: Parameters<typeof this.plotEntitiesController.pushPlotEntities>[1]
  ) => {
    this.blocksHeightState.getNewProbe().then((probe) => {
      window.open(createProbePath(probe.id), '_blank')
      this.plotEntitiesController.pushPlotEntities(probe.id, entities)
    })
  }

  @action
  public resetSelectedTransactions = () => {
    this._selectedTransactions = []
  }

  @action
  public selectUnSelectAllTransactions = () => {
    if (this.isAllSelectedTransactions) {
      this.resetSelectedTransactions()
      return
    }

    this._selectedTransactions = this.transactions
  }

  @computed
  public get isAllSelectedTransactions() {
    if (!this.transactions?.length) return false

    return this.selectedTransactions.length === this.transactions.length
  }
}

export const plotTransactionsOnGraph = new PlotTransactionsOnGraph()
