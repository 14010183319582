import {
  DefaultCounterpartyTableConfig,
  TableColumnConfig,
  CounterpartyTableItem,
} from './CounterpartyTableConfig.types'
import {
  Row,
  getScoreColor,
  Tooltip,
  AssetsListShort,
  TableSortButton,
  TypographyNew,
  Score,
  Amount,
  PopoverBaseContainer,
  CopyIcon,
  EllipsisTypographyBlock,
  EllipsisTypography,
} from '@clain/core/ui-kit'
import AssetsRow from '../../../../ProbeSandbox/ui/AssetsRow'
import { ReactComponent as ExchangeIcon } from '@clain/core/assets/exchange.svg'
import fromUnixTime from 'date-fns/fromUnixTime'
import React from 'react'
import classnames from 'classnames/bind'
import styles from '../CounterpartyTable.scss'
import {
  DEFAULT_CURRENCY_TOKEN_ID,
  isDefaultTokenId,
} from '../../../../ProbeSandbox/utils/convertTokenBalances'
import {
  EntityTableTitle,
  EntityTableText,
  EntityTableNumber,
} from '../../../ui'
import { BaseLink } from '@clain/core/Link'
import { formatMoney, GENERAL_NUMBER_NOTATION } from '@clain/core/utils/format'
import { Stack } from '@clainio/web-platform'

import * as S from './CounterpartyCell.styles'
import { ContentCounterparty } from '@platform/components/EntitiesTable/tables/TransactionsTable/components'
const cx = classnames.bind(styles)

export const getDefaultCounterpartyTableConfig = ({
  cumulative,
  filters,
  coinType,
  goToTransactions,
  showInUSD,
  formatDate,
  formatMoney: formatMoneySettings,
  formatNumber,
  isLoading,
  onChangeSort,
  counterpartyType,
}: DefaultCounterpartyTableConfig<CounterpartyTableItem>) => {
  const isAddressCounterparty = counterpartyType === 'address'
  const getAmountConfig = (
    amount: number | string,
    amountUsd: number | string
  ) => {
    return showInUSD || filters?.includeTokens?.[0]?.id == null
      ? {
          value: amountUsd,
          currency: 'usd',
          code: 'USD',
        }
      : DEFAULT_CURRENCY_TOKEN_ID.includes(filters?.includeTokens?.[0]?.id)
      ? {
          value: amount,
          currency: coinType,
        }
      : {
          value: amount,
          decimals: filters?.includeTokens[0].decimals,
          code: filters?.includeTokens[0].symbol,
        }
  }

  const defaultConfig: Record<
    | 'counterparty'
    | 'category'
    | 'size'
    | 'totalIn'
    | 'totalOut'
    | 'net'
    | 'tokens'
    | 'period',
    TableColumnConfig<CounterpartyTableItem>
  > = {
    counterparty: {
      name: 'counterparty',
      width: isAddressCounterparty ? 3 : 1.5,
      minWidth: '260px',
      renderTitle: () => (
        <Row gap={0.5}>
          <EntityTableTitle>Counterparty</EntityTableTitle>
        </Row>
      ),
      render: (item) => {
        const renderLink = () => {
          if (['Fee', 'Mining'].includes(item.category)) {
            return (
              <>
                <Score value={score} size="xs" />
                <TypographyNew
                  variant="body200NormalCode"
                  color="onBackgroundBase"
                >
                  {item.category}
                </TypographyNew>
              </>
            )
          }
          if (item.type === 'cluster') {
            return (
              <>
                <Score value={score} size="xs" />
                <S.CounterpartyStack
                  align={'center'}
                  gap={'sm'}
                  fullWidth
                  fullHeight
                >
                  {item.entity?.icon && (
                    <S.ClusterEntityIcon $url={item.entity?.icon} />
                  )}
                  <S.CounterpartyRow>
                    <EllipsisTypographyBlock as={Stack} fullWidth>
                      <S.FoggyStyled
                        color="base"
                        content={
                          <CopyIcon
                            value={item.entity?.name || item.clusterId}
                          />
                        }
                      >
                        <Tooltip content={item.entity?.name || item.clusterId}>
                          <span
                            style={{ width: '100%', display: 'inline-flex' }}
                          >
                            <BaseLink
                              to="/:coin/cluster/:clusterId"
                              params={{
                                clusterId: item.clusterId,
                                coin: coinType,
                              }}
                              target="_blank"
                              as={(props) => (
                                <EllipsisTypography
                                  variant="body200NormalCode"
                                  color="onBackgroundBase"
                                  as="a"
                                  {...props}
                                >
                                  {item.entity?.name || item.clusterId}
                                </EllipsisTypography>
                              )}
                            />
                          </span>
                        </Tooltip>
                      </S.FoggyStyled>
                    </EllipsisTypographyBlock>
                  </S.CounterpartyRow>
                </S.CounterpartyStack>
              </>
            )
          } else if (item.type === 'address') {
            return (
              <S.CounterpartyRow gap={0.5}>
                <ContentCounterparty
                  score={item.address.score}
                  address={item.address.address}
                  clusterName={`${item.entity?.name || item.address.id}`}
                  clusterId={item.entity?.id || item.address.id}
                  currency={coinType}
                  contract={item.address.contract}
                  entityIcon={item?.entity?.icon}
                />
              </S.CounterpartyRow>
            )
          } else {
            return null
          }
        }

        // Get the score based on the type
        const score = item.type === 'cluster' ? item.score : item.address.score

        return (
          <Row
            align="between"
            fullWidth
            style={{ overflow: 'hidden', height: '100%' }}
          >
            <Row style={{ overflow: 'hidden', height: '100%' }} fullWidth>
              {renderLink()}
            </Row>
            <Row>
              {!['Fee'].includes(item.category) && (
                <Tooltip content="View transactions">
                  <div
                    className={cx('ExchangeButton')}
                    onClick={() => {
                      const counterpartyName =
                        item.type === 'cluster'
                          ? item.entity?.name || `${item.clusterId}`
                          : `${item.address.address}`
                      goToTransactions?.({
                        ...filters,
                        page: 1,
                        ...(item.type === 'address'
                          ? { counterpartyAddressId: item.address.id }
                          : { counterpartyId: item.clusterId }),
                        counterpartyName,
                      })
                    }}
                  >
                    <ExchangeIcon />
                  </div>
                </Tooltip>
              )}
              <div className={cx('Delimiter')} />
            </Row>
          </Row>
        )
      },
      renderFooter: null,
    },
    category: {
      name: 'category',
      width: 0.5,
      renderTitle: () => (
        <Row gap={0.5}>
          <EntityTableTitle>Category</EntityTableTitle>
        </Row>
      ),
      render: ({ category }) => <EntityTableText>{category}</EntityTableText>,
    },
    size: {
      name: 'size',
      width: isAddressCounterparty ? 0 : 0.5,
      align: 'right',
      renderTitle: () =>
        isAddressCounterparty ? null : (
          <Row gap={0.5}>
            <EntityTableTitle>Size</EntityTableTitle>
            <TableSortButton
              name="size"
              onChangeSort={onChangeSort}
              order={filters.sortOrder}
              sortBy={filters.sortBy}
              disabled={isLoading}
            />
          </Row>
        ),
      render: (item) =>
        item.type === 'address' ? null : (
          <EntityTableNumber>{formatNumber(item.size, 0)}</EntityTableNumber>
        ),
    },
    totalIn: {
      name: 'totalIn',
      width: 1,
      align: 'right',
      className: cx('ClearCell', 'withDelimiter'),
      renderTitle: () => (
        <Row gap={0.5}>
          <EntityTableTitle>In</EntityTableTitle>
          <TableSortButton
            name="total_in"
            onChangeSort={onChangeSort}
            order={filters.sortOrder}
            sortBy={filters.sortBy}
            disabled={isLoading}
          />
        </Row>
      ),
      render: (item) => {
        const formatTotalInConfig = getAmountConfig(
          item.totalIn,
          item.totalInUsd
        )

        const formatCumulativeTotalInConfig = getAmountConfig(
          cumulative.totalIn,
          cumulative.totalInUsd
        )

        const inflow = formatTotalInConfig.value
        const totalInflow = formatCumulativeTotalInConfig.value

        const inflowLeft = inflow
          ? `${(1 - Number(inflow) / Number(totalInflow)) * 100}%`
          : 'unset'

        // Get the score based on the type
        const score = item.type === 'cluster' ? item.score : item.address.score

        const inflowMeterStyle = {
          backgroundColor: getScoreColor(score, true),
          left: inflowLeft,
        }

        return (
          <>
            <div className={cx('InflowMeter')} style={inflowMeterStyle} />
            <div className={cx('TotalIn')}>
              {item.totalInUsd ? (
                <div className={cx('RelativeRow')}>
                  <Amount
                    variant="body200NormalCode"
                    color="onBackgroundBase"
                    value={formatMoneySettings({
                      ...formatTotalInConfig,
                      formats: GENERAL_NUMBER_NOTATION,
                    })}
                    fullValue={formatMoney({
                      ...formatTotalInConfig,
                      precision: 64,
                      minimumSignificantDigits: 1,
                    })}
                    copyValue={formatMoney({
                      ...formatTotalInConfig,
                      precision: 64,
                      code: '',
                      minimumSignificantDigits: 1,
                    })}
                    enabledCopy
                  />
                </div>
              ) : (
                <EntityTableNumber className={cx('EmptyEyeRow', 'RelativeRow')}>
                  -
                </EntityTableNumber>
              )}
            </div>
          </>
        )
      },
      renderFooter: null,
    },
    totalOut: {
      name: 'totalOut',
      width: 1,
      align: 'right',
      className: cx('ClearCell'),
      renderTitle: () => (
        <Row gap={0.5}>
          <EntityTableTitle>Out</EntityTableTitle>
          <TableSortButton
            name="total_out"
            onChangeSort={onChangeSort}
            order={filters.sortOrder}
            sortBy={filters.sortBy}
            disabled={isLoading}
          />
        </Row>
      ),
      render: (item) => {
        const formatTotalOutConfig = getAmountConfig(
          item.totalOut,
          item.totalOutUsd
        )

        const formatCumulativeTotalOutConfig = getAmountConfig(
          cumulative.totalIn,
          cumulative.totalInUsd
        )

        const outflow = formatTotalOutConfig.value
        const totalOutflow = formatCumulativeTotalOutConfig.value

        const outflowRight = outflow
          ? `${(1 - Number(outflow) / Number(totalOutflow)) * 100}%`
          : 'unset'

        const score = item.type === 'cluster' ? item.score : item.address.score

        const outflowMeterStyle = {
          backgroundColor: getScoreColor(score, true),
          right: outflowRight,
        }

        return (
          <>
            <div className={cx('OutflowMeter')} style={outflowMeterStyle} />
            <div className={cx('TotalOut')}>
              {item.totalOutUsd ? (
                <div className={cx('RelativeRow')}>
                  <Amount
                    variant="body200NormalCode"
                    color="onBackgroundBase"
                    value={formatMoneySettings({
                      ...formatTotalOutConfig,
                      formats: GENERAL_NUMBER_NOTATION,
                    })}
                    fullValue={formatMoney({
                      ...formatTotalOutConfig,
                      precision: 64,
                      minimumSignificantDigits: 1,
                    })}
                    copyValue={formatMoney({
                      ...formatTotalOutConfig,
                      precision: 64,
                      code: '',
                      minimumSignificantDigits: 1,
                    })}
                    enabledCopy
                  />
                </div>
              ) : (
                <EntityTableNumber className={cx('EmptyEyeRow', 'RelativeRow')}>
                  -
                </EntityTableNumber>
              )}
            </div>
          </>
        )
      },
      renderFooter: null,
    },
    net: {
      name: 'net',
      width: 1,
      align: 'right',
      renderTitle: () => (
        <Row gap={0.5}>
          <EntityTableTitle>NET</EntityTableTitle>
          <TableSortButton
            name="net"
            onChangeSort={onChangeSort}
            order={filters.sortOrder}
            sortBy={filters.sortBy}
            disabled={isLoading}
          />
        </Row>
      ),
      render: (item) => {
        const formatNetConfig = getAmountConfig(item.net, item.netUsd)

        return (
          <EntityTableNumber>
            <Row align="right" className={cx('EyeRow')}>
              <Amount
                variant="body200NormalCode"
                color="onBackgroundBase"
                value={formatMoneySettings({
                  ...formatNetConfig,
                  formats: GENERAL_NUMBER_NOTATION,
                })}
                fullValue={formatMoney({
                  ...formatNetConfig,
                  precision: 64,
                  minimumSignificantDigits: 1,
                })}
                copyValue={formatMoney({
                  ...formatNetConfig,
                  precision: 64,
                  code: '',
                  minimumSignificantDigits: 1,
                })}
                enabledCopy
              />
            </Row>
          </EntityTableNumber>
        )
      },
      renderFooter: null,
    },
    tokens: {
      name: 'tokens',
      width: 0.5,
      align: 'center',
      renderTitle: () => (
        <Row gap={0.5}>
          <EntityTableTitle>Assets</EntityTableTitle>
        </Row>
      ),
      render: ({ tokens: assets }) => {
        return (
          <PopoverBaseContainer
            maxHeight={300}
            content={
              <AssetsListShort
                assets={assets}
                isDefaultTokenId={isDefaultTokenId}
                size="sm"
                useNewIcons
              />
            }
            anchor={
              <span>
                <AssetsRow
                  className={cx('AssetsRow')}
                  buttonMode
                  tokens={assets}
                />
              </span>
            }
          />
        )
      },
    },
    period: {
      name: 'period',
      width: 1,
      minWidth: '220px',
      align: 'right',
      renderTitle: () => <EntityTableTitle>Activity Period</EntityTableTitle>,
      render: ({ category, active }) => {
        if (['Fee'].includes(category)) return null

        if (!active || !active[0] || !active[1])
          return <EntityTableText>unknown</EntityTableText>

        const [firstSeen, lastSeen] = active

        return (
          <EntityTableText>{`${formatDate(
            fromUnixTime(firstSeen),
            'date-numeric'
          )} - ${formatDate(
            fromUnixTime(lastSeen),
            'date-numeric'
          )}`}</EntityTableText>
        )
      },
    },
  }
  return defaultConfig
}
