import React from 'react'
import { useTransactionBlock } from '../TransactionBlockContext'
import { Col, FeatureTag, Row, Tag, Tooltip } from '@clainio/web-platform'
import isEmpty from 'lodash/isEmpty'
import { HeaderLayout } from '../components'
import { TokenList } from './TokenList'
import { AddressList } from './AddressList'
import { getTagLabelList } from './TransactionBlockUTXO.utils'

export const TransactionBlockUTXO = () => {
  const {
    transaction,
    blockchain,
    isLinkToTransactionDisabled,
    selectedTransaction = false,
    onSelectTransaction,
  } = useTransactionBlock<'UTXO'>()
  const isHasToken = transaction.token && !isEmpty(transaction.token)
  const tags = getTagLabelList(transaction)

  return (
    <Col>
      <HeaderLayout
        memPool={transaction.memPool}
        isLinkToTransactionDisabled={isLinkToTransactionDisabled}
        hash={transaction.hash}
        blockchain={blockchain}
        time={transaction.time}
        selected={selectedTransaction}
        onSelect={
          onSelectTransaction
            ? (checked) => onSelectTransaction(transaction, checked)
            : undefined
        }
      >
        <Row gap={0.25}>
          {isHasToken && (
            <Tag variant={'accent1'} type={'solid'} size={'xs'}>
              OMNI
            </Tag>
          )}
          {tags.length ? (
            <Row gap={0.25}>
              {tags.map((t, index) => (
                <Tooltip
                  placement={'top-start'}
                  disabled={!t.tooltip}
                  content={t.tooltip}
                  key={index}
                >
                  <FeatureTag variant={t.color} size={'xs'}>
                    {t.label}
                  </FeatureTag>
                </Tooltip>
              ))}
            </Row>
          ) : null}
          {transaction?.hasDemix && (
            <Tag variant="critical" type="solid" size="xs">
              AUTO-DEMIX AVAILABLE ON GRAPH
            </Tag>
          )}
          {transaction?.hasCrossChainSwap && (
            <Tag variant="accent1" type="solid" size="xs">
              CROSS-CHAIN SWAP AVAILABLE ON GRAPH
            </Tag>
          )}
        </Row>
      </HeaderLayout>
      <Col>
        {isHasToken && (
          <TokenList blockchain={blockchain} token={transaction.token} />
        )}
        <AddressList />
      </Col>
    </Col>
  )
}
