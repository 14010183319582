import {
  BlockchainIcon,
  ListItem,
  ListItemAtom,
  ListItemContent,
  ListItemProps,
} from '@clain/core/ui-kit'
import { AssetFilterProps } from './ContentValue.types'
import {
  PopoverBaseContainer,
  TypographyNew,
  EllipsisTypography,
} from '@clainio/web-platform'
import * as S from './ContentValue.styles'
import { isDefaultTokenId } from '@platform/components/ProbeSandbox/utils/convertTokenBalances'

const LIST_ITEM_CONFIG: ListItemProps = {
  size: ['md', 'md'],
}

export const AssetFilter: React.FC<AssetFilterProps> = ({
  selected,
  assets,
  blockchain,
  onSelect,
}) => {
  return (
    <PopoverBaseContainer
      content={assets.map((asset) => {
        const variant = asset.scam
          ? 'critical'
          : asset.spam
          ? 'warning'
          : 'base'

        return (
          <ListItem
            key={asset.id}
            {...LIST_ITEM_CONFIG}
            onClick={() => onSelect(asset)}
            /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
            variant={variant}
            content={({ disabledColor, ...params }) => (
              <ListItemContent
                {...params}
                slots={[
                  {
                    type: 'iconText',
                    component: ({ density, color, variant: [body] }) => (
                      <ListItemAtom gap={density}>
                        <div>
                          <BlockchainIcon
                            size="xs"
                            icon={asset.icon}
                            currency={blockchain}
                            color={
                              isDefaultTokenId(asset?.id) ? 'original' : 'grey'
                            }
                          />
                        </div>
                        <TypographyNew color={color?.[0]} variant={body}>
                          {asset.name}
                        </TypographyNew>
                      </ListItemAtom>
                    ),
                  },
                  {
                    type: 'text',
                    component: ({ variant: [body], color }) => {
                      return (
                        <EllipsisTypography color={color?.[1]} variant={body}>
                          {asset.symbol}
                        </EllipsisTypography>
                      )
                    },
                  },
                ]}
              />
            )}
          />
        )
      })}
      placement="bottom"
      trigger="click"
      anchor={
        <span>
          <S.StyledAssetChip
            {...selected}
            blockchain={blockchain}
            isMulti={assets.length > 1}
            color={isDefaultTokenId(selected?.id) ? 'original' : 'grey'}
            variant={
              selected?.scam ? 'scam' : selected?.spam ? 'spam' : 'licit'
            }
            fullWidth
          >
            {selected.symbol}
          </S.StyledAssetChip>
        </span>
      }
    />
  )
}
