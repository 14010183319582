import { action, autorun, IReactionDisposer } from 'mobx'
import {
  IApiServicesStateFacadeDI,
  IApiServicesStateReactionFacade,
  IAPIServiceStateFacade,
  IStateViewModel,
} from '../mobxUtils.types'

export class ApiServicesStateReactionFacade<
  IServiceState extends Record<
    keyof IServiceState,
    IAPIServiceStateFacade<any, any>
  >,
  IParams extends Record<keyof IParams, IStateViewModel<any>>
> implements IApiServicesStateReactionFacade<IServiceState, IParams>
{
  private readonly apiServiceStates: IServiceState
  private readonly apiParams: IParams
  private reactionDisposers: Array<IReactionDisposer> = []

  constructor({
    apiServiceStates,
    apiParams,
  }: IApiServicesStateFacadeDI<IServiceState, IParams>) {
    this.apiServiceStates = apiServiceStates
    this.apiParams = apiParams
  }

  public initDataLoadingReaction = <K extends keyof IServiceState>(
    serviceKey: K,
    directApiParams?: any[]
  ) => {
    const apiServiceState = this.apiServiceStates[serviceKey]
    let apiParams = undefined
    if (this.apiParams && serviceKey in this.apiParams) {
      apiParams = this.apiParams[serviceKey as unknown as keyof IParams].state
    }
    this.reactionDisposers.push(
      autorun(() => {
        try {
          if (directApiParams) {
            apiServiceState.request(...directApiParams, apiParams)
          } else {
            apiServiceState.request(apiParams)
          }
        } catch (error) {
          console.error(error)
        }
      })
    )
  }

  public getStateByService = <K extends keyof IServiceState>(
    key: K
  ): IServiceState[K]['stateViewModel']['state'] => {
    return this.apiServiceStates[key].stateViewModel.state
  }

  public getStateStatusByService = <K extends keyof IServiceState>(
    key: K
  ): IServiceState[K]['stateViewModel']['status'] => {
    return this.apiServiceStates[key].stateViewModel.status
  }

  public getStateErrorByService = <K extends keyof IServiceState>(
    key: K
  ): IServiceState[K]['stateViewModel']['error'] => {
    return this.apiServiceStates[key].stateViewModel.error
  }

  public getApiParamsStateByService = <K extends keyof IParams>(
    key: K
  ): IParams[K]['state'] => {
    return this.apiParams[key].state
  }

  public getApiParamsInitialStateByService = <K extends keyof IParams>(
    key: K
  ): IParams[K]['initialState'] => {
    return this.apiParams[key].initialState
  }

  public getApiParamsDefaultStateByService = <K extends keyof IParams>(
    key: K
  ): IParams[K]['defaultState'] => {
    return this.apiParams[key].defaultState
  }

  public initApiParamsStateByService = <T extends keyof IParams>(key: T) => {
    return (params: Parameters<IParams[T]['initState']>[0]) =>
      this.apiParams[key].initState(params)
  }

  public initDefaultApiParamsStateByService = <T extends keyof IParams>(
    key: T
  ) => {
    return (params: Parameters<IParams[T]['initDefaultState']>[0]) =>
      this.apiParams[key].initDefaultState(params)
  }

  public updateApiParamsStateByService = <T extends keyof IParams>(key: T) => {
    return (params: Parameters<IParams[T]['updateState']>[0]) => {
      return this.apiParams[key].updateState(params)
    }
  }

  public resetApiParamsStateByService = <T extends keyof IParams>(key: T) => {
    return () => this.apiParams[key].resetState
  }

  public injectRequestMethodByService = <K extends keyof IServiceState>(
    key: K
  ): IServiceState[K]['injectRequestMethod'] => {
    return this.apiServiceStates[key].injectRequestMethod
  }

  @action
  private clearData = () => {
    Object.keys(this.apiServiceStates).forEach((key) => {
      this.apiServiceStates[key].stateViewModel.clearData()
    })
    Object.keys(this.apiParams).forEach((key) => {
      this.apiParams[key].clearState()
    })
  }

  @action
  public clear = () => {
    this.reactionDisposers.forEach((disposer) => disposer())
    this.reactionDisposers = []

    this.clearData()
  }
}
