import styled from 'styled-components'
import { createTokenKey, Row, selectToken } from '@clainio/web-platform'
import { IconLeftRightWrapperStyled } from '../../../../TransactionBlock/utxo/TransactionBlockUTXO.styles'

export const CheckboxWrapper = styled(IconLeftRightWrapperStyled)`
  padding-right: ${({ theme }) =>
    selectToken(theme, createTokenKey(['padding', 'xs']))};
`
export const AddressRow = styled(Row)`
  align-items: start;
  height: 100%;
`

export const AddressContainer = styled(Row)`
  height: 24px;
  align-items: center;
`

export const HeaderLeftWrapper = styled(Row)<{ $withPadding: boolean }>`
  padding-left: ${({ $withPadding }) => ($withPadding ? '36px' : 0)};
  width: 100%;
`

export const HeaderRightWrapper = styled(Row)<{ $withPadding: boolean }>`
  padding-right: ${({ $withPadding }) => ($withPadding ? '36px' : 0)};
`
