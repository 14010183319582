import React, { memo, ReactNode } from 'react'
import { TransactionEvmTableItem, TransactionEvmTableProps } from './EvmTable'
import {
  TransactionsUtxoTableItem,
  TransactionsUtxoTableProps,
} from './UtxoTable'
import { Table, useTableStubs } from '@clain/core/ui-kit'
import { useDefaultTransactionsTableConfig } from './useDefaultTransactionsTableConfig'
import classnames from 'classnames/bind'
import styles from './TransactionTable.scss'
import * as S from '../../ui'
import { EntityTableNotFound } from '../../ui'
import { withErrorBoundary } from '@clain/core/ErrorBoundary'

const cx = classnames.bind(styles)

export type Props = Pick<
  | TransactionsUtxoTableProps<TransactionsUtxoTableItem>
  | TransactionEvmTableProps<TransactionEvmTableItem>,
  'data' | 'itemsPerPage' | 'isLoading'
> & {
  config: ReturnType<typeof useDefaultTransactionsTableConfig>
  filtersContent?: ReactNode
}

const TransactionTableComponent = memo(
  ({ data, isLoading, itemsPerPage = 10, config, filtersContent }: Props) => {
    const { stubbedColumns, stubbedData } = useTableStubs(
      Object.values(config),
      data?.map((item, index) => ({
        ...item,
        key: index,
      })),
      itemsPerPage
    )

    const loading = Boolean(data && isLoading)

    return (
      <div className={cx('TransactionTableWrapper')}>
        <S.Container>
          {filtersContent ? (
            <S.FilterContainer>{filtersContent}</S.FilterContainer>
          ) : null}
          <S.TableContainer>
            <Table
              columns={stubbedColumns}
              className={cx('TransactionTable')}
              rowClassName={cx('TrxTableBodyRow')}
              data={stubbedData}
              rowHeight={32}
              loading={loading}
              notFoundComponent={<EntityTableNotFound />}
            />
          </S.TableContainer>
        </S.Container>
      </div>
    )
  }
)

export const TransactionTable = withErrorBoundary(TransactionTableComponent)

TransactionTableComponent.displayName = 'TransactionTable'
