import type { EdgeAttributes } from '@clain/graph'

import {
  formatMoneyByType,
  GENERAL_NUMBER_NOTATION,
} from '@clain/core/utils/format'
import { assertsEntityCurrency, ellipsis } from '@clain/core/utils'
import { icon } from '@clainio/web-platform/dist/components/Icon/iconFn'
import ProbeEdge from './ProbeEdge'
import { TransactionEdgeData } from '../../types'

const WIDTH = 2
const COLOR = 'rgba(141, 158, 193, 1)'
const COLOR_HOVERED = 'rgba(99, 123, 171, 1)'
const STYLE = 'solid'
const COLOR_HIGHLIGHTED = 'rgba(33, 115, 255, 1)'
const LABEL_COLOR = 'rgba(99, 123, 171, 1)'
const LABEL_BACKGROUND_COLOR = 'rgba(237, 241, 247, 1)'
const LABEL_BORDER_RADIUS = 4
const LABEL_SIZE = 12
const LABEL_BORDER_WIDTH_HOVERED = 2
const LABEL_BORDER_COLOR_HOVERED = 'rgba(99, 123, 171, 1)'
const LABEL_COLOR_HIGHLIGHTED = 'rgba(255, 255, 255, 1)'
const LABEL_BACKGROUND_COLOR_HIGHLIGHTED = 'rgba(33, 115, 255, 1)'
const GHOSTED_OPACITY = 0.3

export class TransactionProbeEdge<
  T extends TransactionEdgeData = TransactionEdgeData
> extends ProbeEdge<T> {
  private getLabelText() {
    const formatMoney = formatMoneyByType({
      isShort: this.letterNotation,
      formats: GENERAL_NUMBER_NOTATION,
    })

    if (this.sourceAttributes.data.nodeType === 'utxo_transaction_address') {
      if (this.sourceAttributes.data.coinbase) {
        return 'coinbase'
      }
    }

    if (this.data.amount === undefined) return undefined

    const { amount, amountUsd, token } = this.data

    if (this.layoutSettingsState.state.isUsdCurrency) {
      return formatMoney({
        currency: 'usd',
        value: amountUsd,
        precision: 0,
      })
    }

    if (token) {
      return formatMoney({
        value: amount,
        decimals: this.data.token?.decimals,
        code: ellipsis(this.data.token?.symbol, 25),
        currency: this.data.token.blockchain?.toLocaleLowerCase(),
      })
    }

    assertsEntityCurrency(this.sourceAttributes.data)

    return formatMoney({
      currency: this.sourceAttributes.data.currency,
      value: this.data.amount,
    })
  }

  protected generateAttributes() {
    assertsEntityCurrency(this.sourceAttributes.data)
    const iconSize = parseInt(this.theme.getToken(['icon', 'xs', 'size']))
    const iconSpamColor = this.theme.getToken(['color', 'warning', 'base'])
    const iconScamColor = this.theme.getToken(['color', 'critical', 'base'])

    const targetType = (() => {
      if (
        this.sourceAttributes.data.currency === 'eth' ||
        this.sourceAttributes.data.currency === 'trx'
      )
        return 'arrow'

      if (
        this.sourceAttributes.data.nodeType === 'utxo_transaction_address' ||
        this.sourceAttributes.data.nodeType === 'address'
      )
        return undefined

      return 'arrow'
    })()

    const attributes = {
      width: WIDTH,
      increaseHitArea: 16,
      color: this.getColor?.normal ?? COLOR,
      opacity: this.ghosted ? GHOSTED_OPACITY : undefined,
      style: STYLE,
      targetType,
    } as EdgeAttributes<TransactionEdgeData>

    if (this.hovered && !this.highlighted) {
      attributes.color = this.getColor?.hovered ?? COLOR_HOVERED
      if (attributes.label) {
        attributes.label.border = {
          width: LABEL_BORDER_WIDTH_HOVERED,
          color: this.getColor?.hovered ?? LABEL_BORDER_COLOR_HOVERED,
        }
      }
    }

    if (this.highlighted) {
      attributes.color = this.getColor?.highlighted ?? COLOR_HIGHLIGHTED

      if (attributes.label) {
        attributes.label.color = LABEL_COLOR_HIGHLIGHTED
        attributes.label.fill =
          this.getColor?.highlighted ?? LABEL_BACKGROUND_COLOR_HIGHLIGHTED
      }
    }

    if (this.layers.trxAmount) {
      attributes.label = {
        text: this.getLabelText(),
        color: LABEL_COLOR,
        fontSize: LABEL_SIZE,
        fill: LABEL_BACKGROUND_COLOR,
        borderRadius: LABEL_BORDER_RADIUS,
        padding: [4, 3],
      }
    }

    if (this.data?.token?.scam || this.data?.token?.spam) {
      attributes.label = {
        ...attributes.label,
        gap: 2,
        icon: icon({ variant: 'alertCircle' }),
        iconWidth: iconSize,
        iconHeight: iconSize,
        iconColor: this.data?.token?.scam ? iconScamColor : iconSpamColor,
      }
    }

    return attributes
  }
}
