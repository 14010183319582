import React from 'react'
import { BlockchainIcon, capitalizeFirstLetter, Icon } from '@clain/core/ui-kit'
import { InfoCard } from '@clain/core/ui-kit'
import { Row } from '@clain/core/ui-kit'
import { Score } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import classNames from 'classnames/bind'
import { getCoinName } from '@clain/core/utils/currency'
import {
  getAsset,
  getDirection,
  getThreshold,
  getTrigger,
} from '../../../../../modules/alerts/AlertsViewModel.utils'
import styles from './AlertDetails.scss'
import { AlertDetailsProps } from './AlertDetails.types'
import { isDefaultTokenId } from '@platform/components/ProbeSandbox/utils/convertTokenBalances'
const cx = classNames.bind(styles)

export const AlertDetails: React.FC<AlertDetailsProps> = (props) => {
  const {
    cluster,
    asset,
    currency,
    direction,
    native,
    threshold,
    recurrent,
    type,
    address,
  } = props

  return (
    <Row direction="column" className={cx('AlertsDetails')} fullWidth>
      <div className={cx('AlertsDetailsSection')}>
        <Row className={cx('AlertsDetailsHeader')}>
          <Typography variant="heading5" color="black">
            Funds movement details
          </Typography>
        </Row>
        <InfoCard
          className={cx('AlertsDetailsInfoCard')}
          classNameItem={cx('AlertsDetailsInfoCardItem')}
          size="sm"
          details={[
            {
              label: (
                <Typography variant="body2" color="grey4">
                  {`${type === 'cluster' ? 'Cluster' : 'Address'}:`}
                </Typography>
              ),
              value: (
                <Row gap={0.5}>
                  <Score value={cluster?.score || 0} size="xs" useNewColors />
                  <Typography variant="body1" color="black">
                    {type === 'cluster'
                      ? cluster.entity?.name || cluster?.clusterId
                      : address}
                  </Typography>
                </Row>
              ),
            },
            {
              label: (
                <Typography variant="body2" color="grey4">
                  Blockchain:
                </Typography>
              ),
              value: (
                <Row gap={0.5}>
                  <Icon variant={capitalizeFirstLetter(currency)} size="sm" />
                  <Typography variant="body1" color="black">
                    {getCoinName(currency)}
                  </Typography>
                </Row>
              ),
            },
            {
              label: (
                <Typography variant="body2" color="grey4">
                  Asset:
                </Typography>
              ),
              value: (
                <Row gap={0.5}>
                  <BlockchainIcon
                    size="sm"
                    icon={asset?.icon}
                    currency={asset?.currency}
                    color={isDefaultTokenId(asset?.id) ? 'original' : 'grey'}
                  />
                  <Typography variant="body1" color="black">
                    {getAsset({ asset })}
                  </Typography>
                </Row>
              ),
            },
            {
              label: (
                <Typography variant="body2" color="grey4">
                  Direction:
                </Typography>
              ),
              value: (
                <Typography variant="body1" color="black" transform="uppercase">
                  {getDirection({ direction })}
                </Typography>
              ),
            },
            {
              label: (
                <Typography variant="body2" color="grey4">
                  Threshold:
                </Typography>
              ),
              value: (
                <Typography variant="body1" color="black" transform="uppercase">
                  {getThreshold({ asset, threshold, native })}
                </Typography>
              ),
            },
            {
              label: (
                <Typography variant="body2" color="grey4">
                  Trigger:
                </Typography>
              ),
              value: (
                <Typography variant="body1" color="black" transform="uppercase">
                  {getTrigger({ recurrent })}
                </Typography>
              ),
            },
          ]}
        />
      </div>
    </Row>
  )
}
